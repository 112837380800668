{
  "Flow Block": "Hello world",
  "Flow Circular": "Hello world",
  "Flow Rounded": "Hello world",
  "Redacted": "Hello world",
  "Redacted Script": "Hello world",
  "Material Icons": "favorite add delete settings search",
  "Material Icons Outlined": "favorite add delete settings search",
  "Material Icons Sharp": "favorite add delete settings search",
  "Material Icons Two Tone": "favorite add delete settings search",
  "Material Icons Round": "favorite add delete settings search",
  "Material Symbols Outlined": "favorite add delete settings search",
  "Material Symbols Rounded": "favorite add delete settings search",
  "Material Symbols Sharp": "favorite add delete settings search",
  "Noto Music": "𝄆𝄞♭𝅘𝅥𝅯",
  "Noto Sans Symbols": "☺⚓🜖🝃⚻ ⚗⛰♸⚘⏚",
  "Noto Sans Symbols 2": "♠🖫🙥",
  "Noto Sans SignWriting": "𝡝𝪜𝦦𝪬𝡝𝪩𝡝𝪡𝤅𝧿",
  "Libre Barcode 128": "Hello world",
  "Libre Barcode 128 Text": "Hello world",
  "Libre Barcode 39": "Hello world",
  "Libre Barcode 39 Extended": "Hello world",
  "Libre Barcode 39 Extended Text": "Hello world",
  "Libre Barcode 39 Text": "Hello world",
  "Libre Barcode EAN13 Text": "Hello world",
  "Reem Kufi": "حقوق الإنسان وازدراؤها قد أفضيا",
  "Reem Kufi Fun": "حقوق الإنسان وازدراؤها قد أفضيا",
  "Reem Kufi Ink": "حقوق الإنسان وازدراؤها قد أفضيا",
  "Aref Ruqaa": "حقوق الإنسان وازدراؤها قد أفضيا",
  "Aref Ruqaa Ink": "حقوق الإنسان وازدراؤها قد أفضيا",
  "Konkhmer Sleokchher": "សួស្តី​ពិភពលោក",
  "Noto Znamenny Musical Notation": "𜾔𜼰𜼺𜼆 𜾒𜼱𜼻𜼇𜼤"
}
