{
  "tags": [
    {
      "name": "1700s",
      "sample": "Jacques Francois"
    },
    {
      "name": "1900s",
      "sample": "Amiri"
    },
    {
      "name": "1920s",
      "sample": "Cagliostro"
    },
    {
      "name": "1930s",
      "sample": "Englebert"
    },
    {
      "name": "1940s",
      "sample": "Fanwood Text"
    },
    {
      "name": "1950s",
      "sample": "Atomic Age"
    },
    {
      "name": "1960s",
      "sample": "Boogaloo"
    },
    {
      "name": "1970s",
      "sample": "Hachi Maru Pop"
    },
    {
      "name": "1980s",
      "sample": "Press Start 2P"
    },
    {
      "name": "1990s",
      "sample": "Anybody"
    },
    {
      "name": "ancient",
      "sample": "Caesar Dressing"
    },
    {
      "name": "antiqua",
      "sample": "Federant"
    },
    {
      "name": "arabic",
      "sample": "Alkalami"
    },
    {
      "name": "art deco",
      "sample": "Aladin"
    },
    {
      "name": "art nouveau",
      "sample": "Amarante"
    },
    {
      "name": "bamboo",
      "sample": "Hanalei"
    },
    {
      "name": "barcode",
      "sample": "Libre Barcode 128"
    },
    {
      "name": "blackletter",
      "sample": "Fruktur"
    },
    {
      "name": "block",
      "sample": "Alumni Sans Inline One"
    },
    {
      "name": "brush",
      "sample": "Agbalumo"
    },
    {
      "name": "bubbly",
      "sample": "Crafty Girls"
    },
    {
      "name": "calligraphic",
      "sample": "Amita"
    },
    {
      "name": "cambodian",
      "sample": "Bokor"
    },
    {
      "name": "caps",
      "sample": "Aboreto"
    },
    {
      "name": "cartoon",
      "sample": "Bangers"
    },
    {
      "name": "celtic",
      "sample": "Cormorant Infant"
    },
    {
      "name": "code",
      "sample": "Anaheim"
    },
    {
      "name": "collegiate",
      "sample": "Alumni Sans Collegiate One"
    },
    {
      "name": "color",
      "sample": "Aref Ruqaa Ink"
    },
    {
      "name": "comic",
      "sample": "Architects Daughter"
    },
    {
      "name": "compact",
      "sample": "Adamina"
    },
    {
      "name": "condensed",
      "sample": "Abel"
    },
    {
      "name": "connected",
      "sample": "Grandiflora One"
    },
    {
      "name": "copperplate",
      "sample": "Euphoria Script"
    },
    {
      "name": "curls",
      "sample": "Are You Serious"
    },
    {
      "name": "cursive",
      "sample": "Alex Brush"
    },
    {
      "name": "cute",
      "sample": "Bonbon"
    },
    {
      "name": "decorative",
      "sample": "Bellota"
    },
    {
      "name": "diagram",
      "sample": "Linefont"
    },
    {
      "name": "didone",
      "sample": "Abril Fatface"
    },
    {
      "name": "dingbat",
      "sample": "Noto Sans Symbols"
    },
    {
      "name": "disco",
      "sample": "Audiowide"
    },
    {
      "name": "distressed",
      "sample": "Rubik Dirt"
    },
    {
      "name": "dotted",
      "sample": "Codystar"
    },
    {
      "name": "egyptian",
      "sample": "Alfa Slab One"
    },
    {
      "name": "elegant",
      "sample": "Babylonica"
    },
    {
      "name": "engraved",
      "sample": "Bentham"
    },
    {
      "name": "eurostile",
      "sample": "Iceland"
    },
    {
      "name": "expanded",
      "sample": "BhuTuka Expanded One"
    },
    {
      "name": "fat",
      "sample": "Bagel Fat One"
    },
    {
      "name": "fat face",
      "sample": "Asset"
    },
    {
      "name": "formal",
      "sample": "Allison"
    },
    {
      "name": "fraktur",
      "sample": "Astloch"
    },
    {
      "name": "french",
      "sample": "Borel"
    },
    {
      "name": "friendly",
      "sample": "Amaranth"
    },
    {
      "name": "fun",
      "sample": "Bubblegum Sans"
    },
    {
      "name": "funky",
      "sample": "Bahianita"
    },
    {
      "name": "futuristic",
      "sample": "Anta"
    },
    {
      "name": "fuzzy",
      "sample": "Margarine"
    },
    {
      "name": "game",
      "sample": "DotGothic16"
    },
    {
      "name": "geometric",
      "sample": "Alata"
    },
    {
      "name": "german",
      "sample": "Oldenburg"
    },
    {
      "name": "glitch",
      "sample": "Rubik Glitch"
    },
    {
      "name": "gothic",
      "sample": "Balthazar"
    },
    {
      "name": "gradient",
      "sample": "Bungee Spice"
    },
    {
      "name": "graffiti",
      "sample": "Rubik Spray Paint"
    },
    {
      "name": "groovy",
      "sample": "Acme"
    },
    {
      "name": "grotesque",
      "sample": "Archivo Black"
    },
    {
      "name": "grunge",
      "sample": "Averia Gruesa Libre"
    },
    {
      "name": "halloween",
      "sample": "Butcherman"
    },
    {
      "name": "handwritten",
      "sample": "Chilanka"
    },
    {
      "name": "headlines",
      "sample": "Antic Didone"
    },
    {
      "name": "hearts",
      "sample": "Emilys Candy"
    },
    {
      "name": "heavy",
      "sample": "Anton"
    },
    {
      "name": "hebrew",
      "sample": "David Libre"
    },
    {
      "name": "high contrast",
      "sample": "Arya"
    },
    {
      "name": "hinted",
      "sample": "Arvo"
    },
    {
      "name": "humanist",
      "sample": "Alegreya Sans"
    },
    {
      "name": "icons",
      "sample": "Material Icons"
    },
    {
      "name": "japanese",
      "sample": "Aoboshi One"
    },
    {
      "name": "khmer",
      "sample": "Cambo"
    },
    {
      "name": "kids",
      "sample": "Butterfly Kids"
    },
    {
      "name": "knit",
      "sample": "Jacquard 12"
    },
    {
      "name": "korean",
      "sample": "Diphylleia"
    },
    {
      "name": "large sizes",
      "sample": "Baumans"
    },
    {
      "name": "legibility",
      "sample": "Alike"
    },
    {
      "name": "light",
      "sample": "Alumni Sans"
    },
    {
      "name": "lines",
      "sample": "Akronim"
    },
    {
      "name": "literacy",
      "sample": "ABeeZee"
    },
    {
      "name": "literature",
      "sample": "Alegreya"
    },
    {
      "name": "looped",
      "sample": "Taviraj"
    },
    {
      "name": "loopless",
      "sample": "Athiti"
    },
    {
      "name": "love",
      "sample": "Ballet"
    },
    {
      "name": "low contrast",
      "sample": "AR One Sans"
    },
    {
      "name": "manga",
      "sample": "Mochiy Pop One"
    },
    {
      "name": "marker",
      "sample": "Combo"
    },
    {
      "name": "modern",
      "sample": "Aclonica"
    },
    {
      "name": "monolinear",
      "sample": "Azeret Mono"
    },
    {
      "name": "narrow",
      "sample": "Akshar"
    },
    {
      "name": "needlepoint",
      "sample": "Jacquarda Bastarda 9"
    },
    {
      "name": "neoclassical",
      "sample": "GFS Didot"
    },
    {
      "name": "old style",
      "sample": "Alice"
    },
    {
      "name": "outline",
      "sample": "Blaka Hollow"
    },
    {
      "name": "pixel",
      "sample": "Handjet"
    },
    {
      "name": "playful",
      "sample": "Barriecito"
    },
    {
      "name": "pulp",
      "sample": "Carter One"
    },
    {
      "name": "retro",
      "sample": "Glass Antiqua"
    },
    {
      "name": "rock",
      "sample": "Metal Mania"
    },
    {
      "name": "roman",
      "sample": "Cinzel Decorative"
    },
    {
      "name": "romantic",
      "sample": "Beth Ellen"
    },
    {
      "name": "round",
      "sample": "Asap"
    },
    {
      "name": "scrawl",
      "sample": "Amatic SC"
    },
    {
      "name": "script",
      "sample": "Aguafina Script"
    },
    {
      "name": "shadow",
      "sample": "Bungee Shade"
    },
    {
      "name": "signage",
      "sample": "Barrio"
    },
    {
      "name": "sketch",
      "sample": "Cabin Sketch"
    },
    {
      "name": "slab",
      "sample": "Alike Angular"
    },
    {
      "name": "slanted",
      "sample": "Alkatra"
    },
    {
      "name": "small caps",
      "sample": "Alegreya SC"
    },
    {
      "name": "small sizes",
      "sample": "Almendra"
    },
    {
      "name": "speed",
      "sample": "Faster One"
    },
    {
      "name": "spicy",
      "sample": "Black And White Picture"
    },
    {
      "name": "sport",
      "sample": "Jersey 10"
    },
    {
      "name": "square",
      "sample": "Aldrich"
    },
    {
      "name": "stencil",
      "sample": "Allerta Stencil"
    },
    {
      "name": "surf",
      "sample": "Original Surfer"
    },
    {
      "name": "swash",
      "sample": "Berkshire Swash"
    },
    {
      "name": "swirl",
      "sample": "Elsie"
    },
    {
      "name": "tall",
      "sample": "Antonio"
    },
    {
      "name": "tapered",
      "sample": "Denk One"
    },
    {
      "name": "tattoo",
      "sample": "Miltonian"
    },
    {
      "name": "techno",
      "sample": "Agdasima"
    },
    {
      "name": "thin",
      "sample": "Actor"
    },
    {
      "name": "transitional",
      "sample": "Amethysta"
    },
    {
      "name": "typewriter",
      "sample": "Courier Prime"
    },
    {
      "name": "uneven",
      "sample": "Atma"
    },
    {
      "name": "unicase",
      "sample": "Cormorant Unicase"
    },
    {
      "name": "urban",
      "sample": "Montserrat"
    },
    {
      "name": "vintage",
      "sample": "Calistoga"
    },
    {
      "name": "western",
      "sample": "Arbutus"
    },
    {
      "name": "whimsical",
      "sample": "Almendra Display"
    },
    {
      "name": "wide",
      "sample": "Bellota Text"
    },
    {
      "name": "wireframe",
      "sample": "Balsamiq Sans"
    }
  ],
  "categories": ["display", "handwriting", "monospace", "sans-serif", "serif"],
  "subsets": [
    "adlam",
    "ahom",
    "anatolian-hieroglyphs",
    "arabic",
    "armenian",
    "avestan",
    "balinese",
    "bamum",
    "bassa-vah",
    "batak",
    "bengali",
    "bhaiksuki",
    "brahmi",
    "braille",
    "buginese",
    "buhid",
    "canadian-aboriginal",
    "carian",
    "caucasian-albanian",
    "chakma",
    "cham",
    "cherokee",
    "chinese-hongkong",
    "chinese-simplified",
    "chinese-traditional",
    "chorasmian",
    "coptic",
    "cuneiform",
    "cypriot",
    "cypro-minoan",
    "cyrillic",
    "cyrillic-ext",
    "deseret",
    "devanagari",
    "dogra",
    "duployan",
    "egyptian-hieroglyphs",
    "elbasan",
    "elymaic",
    "emoji",
    "ethiopic",
    "georgian",
    "glagolitic",
    "gothic",
    "grantha",
    "greek",
    "greek-ext",
    "gujarati",
    "gunjala-gondi",
    "gurmukhi",
    "hanifi-rohingya",
    "hanunoo",
    "hatran",
    "hebrew",
    "imperial-aramaic",
    "indic-siyaq-numbers",
    "inscriptional-pahlavi",
    "inscriptional-parthian",
    "japanese",
    "javanese",
    "kaithi",
    "kannada",
    "kawi",
    "kayah-li",
    "kharoshthi",
    "khitan-small-script",
    "khmer",
    "khojki",
    "khudawadi",
    "korean",
    "lao",
    "latin",
    "latin-ext",
    "lepcha",
    "limbu",
    "linear-a",
    "linear-b",
    "lisu",
    "lycian",
    "lydian",
    "mahajani",
    "makasar",
    "malayalam",
    "mandaic",
    "manichaean",
    "marchen",
    "masaram-gondi",
    "math",
    "mayan-numerals",
    "medefaidrin",
    "meetei-mayek",
    "mende-kikakui",
    "meroitic",
    "meroitic-cursive",
    "meroitic-hieroglyphs",
    "miao",
    "modi",
    "mongolian",
    "mro",
    "multani",
    "music",
    "myanmar",
    "nabataean",
    "nag-mundari",
    "nandinagari",
    "new-tai-lue",
    "newa",
    "nko",
    "nushu",
    "nyiakeng-puachue-hmong",
    "ogham",
    "ol-chiki",
    "old-hungarian",
    "old-italic",
    "old-north-arabian",
    "old-permic",
    "old-persian",
    "old-sogdian",
    "old-south-arabian",
    "old-turkic",
    "old-uyghur",
    "oriya",
    "osage",
    "osmanya",
    "ottoman-siyaq-numbers",
    "pahawh-hmong",
    "palmyrene",
    "pau-cin-hau",
    "phags-pa",
    "phoenician",
    "psalter-pahlavi",
    "rejang",
    "runic",
    "samaritan",
    "saurashtra",
    "sharada",
    "shavian",
    "siddham",
    "signwriting",
    "sinhala",
    "sogdian",
    "sora-sompeng",
    "soyombo",
    "sundanese",
    "syloti-nagri",
    "symbols",
    "syriac",
    "tagalog",
    "tagbanwa",
    "tai-le",
    "tai-tham",
    "tai-viet",
    "takri",
    "tamil",
    "tamil-supplement",
    "tangsa",
    "tangut",
    "telugu",
    "thaana",
    "thai",
    "tibetan",
    "tifinagh",
    "tirhuta",
    "toto",
    "ugaritic",
    "vai",
    "vietnamese",
    "vithkuqi",
    "wancho",
    "warang-citi",
    "yezidi",
    "yi",
    "zanabazar-square",
    "znamenny"
  ],
  "variants": [
    "100",
    "100italic",
    "200",
    "200italic",
    "300",
    "300italic",
    "500",
    "500italic",
    "600",
    "600italic",
    "700",
    "700italic",
    "800",
    "800italic",
    "900",
    "900italic",
    "italic",
    "regular"
  ],
  "totalFamilies": 1739
}
