[
  {
    "family": "42dot Sans",
    "variants": ["300", "regular", "500", "600", "700", "800"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": ["korean"],
    "lineNumber": 2,
    "variable": false,
    "lastModified": "2025-03-21"
  },
  {
    "family": "ABeeZee",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["literacy", "kids"],
    "lineNumber": 3,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "ADLaM Display",
    "variants": ["regular"],
    "subsets": ["adlam", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 4,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "AR One Sans",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 5,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Abel",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 6,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Abhaya Libre",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 7,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aboreto",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["caps"],
    "lineNumber": 8,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Abril Fatface",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "elegant", "didone", "headlines"],
    "lineNumber": 9,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Abyssinica SIL",
    "variants": ["regular"],
    "subsets": ["ethiopic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 10,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Aclonica",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern", "friendly"],
    "lineNumber": 11,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Acme",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["cartoon", "comic", "groovy", "headlines"],
    "lineNumber": 12,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Actor",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["thin", "legibility"],
    "lineNumber": 13,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Adamina",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["compact", "small sizes", "transitional"],
    "lineNumber": 14,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Advent Pro",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 15,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Afacad",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic-ext",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 16,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Afacad Flux",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 17,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Agbalumo",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["brush", "slanted"],
    "lineNumber": 18,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Agdasima",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 19,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Agu Display",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 20,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Aguafina Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "script"],
    "lineNumber": 21,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Akatab",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["tifinagh", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 22,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Akaya Kanadaka",
    "variants": ["regular"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 23,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Akaya Telivigala",
    "variants": ["regular"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 24,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Akronim",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["brush", "lines"],
    "lineNumber": 25,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Akshar",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed", "narrow"],
    "lineNumber": 26,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Aladin",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["art deco"],
    "lineNumber": 27,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alata",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "low contrast"],
    "lineNumber": 28,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Alatsi",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern", "condensed", "geometric"],
    "lineNumber": 29,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Albert Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 30,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aldrich",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["square", "techno"],
    "lineNumber": 31,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alef",
    "variants": ["regular", "700"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 32,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Alegreya",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["literature"],
    "lineNumber": 33,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Alegreya SC",
    "variants": [
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["small caps", "literature"],
    "lineNumber": 34,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alegreya Sans",
    "variants": [
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["humanist", "literature"],
    "lineNumber": 35,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Alegreya Sans SC",
    "variants": [
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["small caps", "literature"],
    "lineNumber": 36,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aleo",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 37,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alex Brush",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "cursive"],
    "lineNumber": 38,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alexandria",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 39,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alfa Slab One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slab", "heavy", "egyptian"],
    "lineNumber": 40,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alice",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style", "headlines"],
    "lineNumber": 41,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alike",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["legibility", "slab"],
    "lineNumber": 42,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alike Angular",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["legibility", "slab"],
    "lineNumber": 43,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alkalami",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 44,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alkatra",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["bengali", "devanagari", "oriya", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 45,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Allan",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 46,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Allerta",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 47,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Allerta Stencil",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["stencil"],
    "lineNumber": 48,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Allison",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "elegant", "formal", "slanted"],
    "lineNumber": 49,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Allura",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 50,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Almarai",
    "variants": ["300", "regular", "700", "800"],
    "subsets": ["arabic", "latin"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 51,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Almendra",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["whimsical", "small sizes"],
    "lineNumber": 52,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Almendra Display",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical", "literature"],
    "lineNumber": 53,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Almendra SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps", "literature"],
    "lineNumber": 54,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alumni Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow", "condensed", "light", "thin"],
    "lineNumber": 55,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alumni Sans Collegiate One",
    "variants": ["regular", "italic"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["outline", "collegiate"],
    "lineNumber": 56,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alumni Sans Inline One",
    "variants": ["regular", "italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["block", "heavy"],
    "lineNumber": 57,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Alumni Sans Pinstripe",
    "variants": ["regular", "italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["light", "thin"],
    "lineNumber": 58,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amarante",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["art nouveau"],
    "lineNumber": 59,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amaranth",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 60,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amatic SC",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "hebrew", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["small caps", "scrawl"],
    "lineNumber": 61,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amethysta",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 62,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amiko",
    "variants": ["regular", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 63,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amiri",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["1900s", "elegant"],
    "lineNumber": 64,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Amiri Quran",
    "variants": ["regular"],
    "subsets": ["arabic", "latin"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 65,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Amita",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 66,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anaheim",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["code"],
    "lineNumber": 67,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Andada Pro",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 68,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Andika",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["literacy"],
    "lineNumber": 69,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Anek Bangla",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 70,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Devanagari",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 71,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Gujarati",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 72,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Gurmukhi",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 73,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anek Kannada",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 74,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Latin",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 75,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anek Malayalam",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["malayalam", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 76,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Odia",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["oriya", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 77,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Tamil",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 78,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Anek Telugu",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 79,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Angkor",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["literacy"],
    "lineNumber": 80,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Annapurna SIL",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 81,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Annie Use Your Telescope",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 82,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Anonymous Pro",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "greek", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 83,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anta",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern", "techno", "futuristic"],
    "lineNumber": 84,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Antic",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["thin", "friendly"],
    "lineNumber": 85,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Antic Didone",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["didone", "headlines"],
    "lineNumber": 86,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Antic Slab",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab", "headlines"],
    "lineNumber": 87,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anton",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 88,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anton SC",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["small caps"],
    "lineNumber": 89,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Antonio",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow", "futuristic", "tall", "modern", "techno"],
    "lineNumber": 90,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anuphan",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 91,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Anybody",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["eurostile", "1990s"],
    "lineNumber": 92,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aoboshi One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 93,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Arapey",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["didone"],
    "lineNumber": 94,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arbutus",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["heavy", "western"],
    "lineNumber": 95,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arbutus Slab",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 96,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Architects Daughter",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["comic"],
    "lineNumber": 97,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Archivo",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 98,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Archivo Black",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque", "heavy"],
    "lineNumber": 99,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Archivo Narrow",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 100,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Are You Serious",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["whimsical", "curls", "cute", "kids", "swirl"],
    "lineNumber": 101,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aref Ruqaa",
    "variants": ["regular", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["calligraphic"],
    "lineNumber": 102,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Aref Ruqaa Ink",
    "variants": ["regular", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["color", "calligraphic"],
    "lineNumber": 103,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Arima",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": [
      "greek",
      "greek-ext",
      "malayalam",
      "tamil",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["low contrast"],
    "lineNumber": 104,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arimo",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["legibility", "literacy"],
    "lineNumber": 105,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arizonia",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "brush"],
    "lineNumber": 106,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Armata",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 107,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arsenal",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin", "light"],
    "lineNumber": 108,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arsenal SC",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["small caps"],
    "lineNumber": 109,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Artifika",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["friendly"],
    "lineNumber": 110,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arvo",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab", "egyptian", "hinted", "geometric"],
    "lineNumber": 111,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Arya",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["high contrast"],
    "lineNumber": 112,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Asap",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 113,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Asap Condensed",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 114,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Asar",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["round"],
    "lineNumber": 115,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Asset",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wide", "fat face", "engraved", "high contrast"],
    "lineNumber": 116,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Assistant",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 117,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Astloch",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["monolinear", "fraktur", "whimsical"],
    "lineNumber": 118,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Asul",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 119,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Athiti",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["loopless", "friendly", "humanist"],
    "lineNumber": 120,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Atkinson Hyperlegible",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["literacy"],
    "lineNumber": 121,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Atkinson Hyperlegible Mono",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 122,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Atkinson Hyperlegible Next",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 123,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Atma",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven", "whimsical"],
    "lineNumber": 124,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Atomic Age",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["modern", "1950s"],
    "lineNumber": 125,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Aubrey",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["art nouveau"],
    "lineNumber": 126,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Audiowide",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "futuristic"],
    "lineNumber": 127,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Autour One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 128,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Average",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["literature"],
    "lineNumber": 129,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Average Sans",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin", "headlines"],
    "lineNumber": 130,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Averia Gruesa Libre",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 131,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Averia Libre",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 132,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Averia Sans Libre",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 133,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Averia Serif Libre",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 134,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Azeret Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["monolinear"],
    "lineNumber": 135,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "B612",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["literacy"],
    "lineNumber": 136,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "B612 Mono",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["literacy"],
    "lineNumber": 137,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BIZ UDGothic",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "greek-ext", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 138,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BIZ UDMincho",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "greek-ext", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 139,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BIZ UDPGothic",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "greek-ext", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 140,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BIZ UDPMincho",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "greek-ext", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 141,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Babylonica",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl", "calligraphic", "elegant"],
    "lineNumber": 142,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bacasime Antique",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 143,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bad Script",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 144,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Badeen Display",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 145,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bagel Fat One",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "display",
    "tags": ["korean", "fat", "cute", "round"],
    "lineNumber": 146,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Bahiana",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven"],
    "lineNumber": 147,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bahianita",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["narrow", "condensed", "funky"],
    "lineNumber": 148,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bai Jamjuree",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 149,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bakbak One",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy"],
    "lineNumber": 150,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Ballet",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "formal", "love", "romantic"],
    "lineNumber": 151,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["devanagari", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 152,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Bhai 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["gujarati", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 153,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Bhaijaan 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 154,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Bhaina 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["oriya", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 155,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Chettan 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["malayalam", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 156,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Da 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["bengali", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 157,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Paaji 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["gurmukhi", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 158,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Tamma 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["kannada", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 159,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Tammudu 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["telugu", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 160,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baloo Thambi 2",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["tamil", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 161,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Balsamiq Sans",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "display",
    "tags": ["round", "friendly", "wireframe"],
    "lineNumber": 162,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Balthazar",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["gothic", "headlines"],
    "lineNumber": 163,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bangers",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["comic", "slanted", "cartoon", "caps"],
    "lineNumber": 164,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Barlow",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 165,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Barlow Condensed",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 166,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Barlow Semi Condensed",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 167,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Barriecito",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven", "fun", "playful"],
    "lineNumber": 168,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Barrio",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven", "signage"],
    "lineNumber": 169,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Basic",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 170,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baskervville",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 171,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Baskervville SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 172,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Battambang",
    "variants": ["100", "300", "regular", "700", "900"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["tall"],
    "lineNumber": 173,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Baumans",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["monolinear", "large sizes", "headlines", "geometric"],
    "lineNumber": 174,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bayon",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 175,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Be Vietnam Pro",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 176,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Beau Rivage",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "elegant", "formal"],
    "lineNumber": 177,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bebas Neue",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["caps", "compact", "heavy", "block"],
    "lineNumber": 178,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Beiruti",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 179,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Belanosima",
    "variants": ["regular", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 180,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Belgrano",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 181,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bellefair",
    "variants": ["regular"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["light", "tall", "thin"],
    "lineNumber": 182,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Belleza",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 183,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bellota",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical", "large sizes", "cute", "decorative"],
    "lineNumber": 184,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bellota Text",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["large sizes", "wide"],
    "lineNumber": 185,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BenchNine",
    "variants": ["300", "regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed", "headlines"],
    "lineNumber": 186,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Benne",
    "variants": ["regular"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 187,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bentham",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["engraved"],
    "lineNumber": 188,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Berkshire Swash",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["swash"],
    "lineNumber": 189,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Besley",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 190,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Beth Ellen",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "romantic"],
    "lineNumber": 191,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bevan",
    "variants": ["regular", "italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "heavy"],
    "lineNumber": 192,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BhuTuka Expanded One",
    "variants": ["regular"],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["wide", "thin", "light", "expanded"],
    "lineNumber": 193,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Big Shoulders",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 194,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Big Shoulders Inline",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 195,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Big Shoulders Stencil",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 196,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Bigelow Rules",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven", "whimsical"],
    "lineNumber": 197,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bigshot One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["didone"],
    "lineNumber": 198,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bilbo",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["headlines"],
    "lineNumber": 199,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bilbo Swash Caps",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["swash", "headlines"],
    "lineNumber": 200,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "BioRhyme",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 201,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "BioRhyme Expanded",
    "variants": ["200", "300", "regular", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "expanded"],
    "lineNumber": 202,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Birthstone",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "small sizes", "formal"],
    "lineNumber": 203,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Birthstone Bounce",
    "variants": ["regular", "500"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "formal", "playful"],
    "lineNumber": 204,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Biryani",
    "variants": ["200", "300", "regular", "600", "700", "800", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear", "geometric"],
    "lineNumber": 205,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bitter",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 206,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Black And White Picture",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["swirl", "spicy", "playful", "fuzzy"],
    "lineNumber": 207,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Black Han Sans",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 208,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Black Ops One",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil", "large sizes", "low contrast"],
    "lineNumber": 209,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Blaka",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["gothic", "arabic"],
    "lineNumber": 210,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Blaka Hollow",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["gothic", "arabic", "outline"],
    "lineNumber": 211,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Blaka Ink",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["arabic", "color"],
    "lineNumber": 212,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Blinker",
    "variants": ["100", "200", "300", "regular", "600", "700", "800", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "square"],
    "lineNumber": 213,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Bodoni Moda",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 214,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bodoni Moda SC",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 215,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Bokor",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["cambodian", "khmer"],
    "lineNumber": 216,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Boldonse",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["modern"],
    "lineNumber": 217,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Bona Nova",
    "variants": ["regular", "italic", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 218,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Bona Nova SC",
    "variants": ["regular", "italic", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 219,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bonbon",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cute", "decorative", "headlines"],
    "lineNumber": 220,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bonheur Royale",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "calligraphic", "cursive", "elegant", "formal"],
    "lineNumber": 221,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Boogaloo",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["1960s"],
    "lineNumber": 222,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Borel",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["french", "cursive", "connected"],
    "lineNumber": 223,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bowlby One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["heavy", "headlines"],
    "lineNumber": 224,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bowlby One SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["small caps", "heavy", "headlines"],
    "lineNumber": 225,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Braah One",
    "variants": ["regular"],
    "subsets": ["gurmukhi", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["block"],
    "lineNumber": 226,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Brawler",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["compact"],
    "lineNumber": 227,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bree Serif",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["friendly"],
    "lineNumber": 228,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bricolage Grotesque",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 229,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Bruno Ace",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["techno", "geometric"],
    "lineNumber": 230,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bruno Ace SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["small caps", "techno", "geometric"],
    "lineNumber": 231,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Brygada 1918",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["1900s"],
    "lineNumber": 232,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Bubblegum Sans",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 233,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bubbler One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin"],
    "lineNumber": 234,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Buda",
    "variants": ["300"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["thin"],
    "lineNumber": 235,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Buenard",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 236,
    "variable": false,
    "lastModified": "2025-03-05"
  },
  {
    "family": "Bungee",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage"],
    "lineNumber": 237,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bungee Hairline",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["thin", "signage"],
    "lineNumber": 238,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bungee Inline",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage"],
    "lineNumber": 239,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bungee Outline",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline", "signage"],
    "lineNumber": 240,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bungee Shade",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["shadow", "signage"],
    "lineNumber": 241,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Bungee Spice",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["gradient", "color"],
    "lineNumber": 242,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Bungee Tint",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["color", "caps"],
    "lineNumber": 243,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Butcherman",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["halloween"],
    "lineNumber": 244,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Butterfly Kids",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["kids", "cute"],
    "lineNumber": 245,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Bytesized",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["pixel", "game", "small sizes"],
    "lineNumber": 246,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Cabin",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "geometric"],
    "lineNumber": 247,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cabin Condensed",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed", "humanist"],
    "lineNumber": 248,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cabin Sketch",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["sketch"],
    "lineNumber": 249,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cactus Classical Serif",
    "variants": ["regular"],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 250,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Caesar Dressing",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["ancient", "roman"],
    "lineNumber": 251,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cagliostro",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["1920s"],
    "lineNumber": 252,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cairo",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["calligraphic", "modern", "wide"],
    "lineNumber": 253,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cairo Play",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["color"],
    "lineNumber": 254,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Caladea",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 255,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Calistoga",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "vintage"],
    "lineNumber": 256,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Calligraffitti",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 257,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cambay",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 258,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Cambo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["khmer", "condensed"],
    "lineNumber": 259,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Candal",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 260,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cantarell",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 261,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cantata One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["didone"],
    "lineNumber": 262,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cantora One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "headlines"],
    "lineNumber": 263,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Caprasimo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy"],
    "lineNumber": 264,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Capriola",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 265,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Caramel",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "cursive", "elegant", "formal"],
    "lineNumber": 266,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Carattere",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "elegant"],
    "lineNumber": 267,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cardo",
    "variants": ["regular", "italic", "700"],
    "subsets": ["greek", "greek-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 268,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Carlito",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 269,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Carme",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 270,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Carrois Gothic",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["gothic"],
    "lineNumber": 271,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Carrois Gothic SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["small caps", "gothic"],
    "lineNumber": 272,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Carter One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["pulp"],
    "lineNumber": 273,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Castoro",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 274,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Castoro Titling",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["caps"],
    "lineNumber": 275,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Catamaran",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 276,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Caudex",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["greek", "greek-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 277,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Caveat",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 278,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Caveat Brush",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 279,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cedarville Cursive",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 280,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ceviche One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1960s", "headlines"],
    "lineNumber": 281,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chakra Petch",
    "variants": [
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square", "techno"],
    "lineNumber": 282,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Changa",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square"],
    "lineNumber": 283,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Changa One",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["heavy"],
    "lineNumber": 284,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chango",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy"],
    "lineNumber": 285,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Charis SIL",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 286,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Charm",
    "variants": ["regular", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 287,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Charmonman",
    "variants": ["regular", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "tall"],
    "lineNumber": 288,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chathura",
    "variants": ["100", "300", "regular", "700", "800"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["square"],
    "lineNumber": 289,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Chau Philomene One",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["headlines"],
    "lineNumber": 290,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chela One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 291,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chelsea Market",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["kids", "fun"],
    "lineNumber": 292,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chenla",
    "variants": ["regular"],
    "subsets": ["khmer"],
    "category": "display",
    "tags": ["cambodian", "khmer"],
    "lineNumber": 293,
    "variable": false,
    "lastModified": "2022-09-22"
  },
  {
    "family": "Cherish",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "cursive", "elegant"],
    "lineNumber": 294,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cherry Bomb One",
    "variants": ["regular"],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "cute", "japanese", "round"],
    "lineNumber": 295,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Cherry Cream Soda",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["1950s"],
    "lineNumber": 296,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cherry Swash",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swash", "headlines"],
    "lineNumber": 297,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chewy",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["cartoon"],
    "lineNumber": 298,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chicle",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "groovy"],
    "lineNumber": 299,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chilanka",
    "variants": ["regular"],
    "subsets": ["malayalam", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten", "kids"],
    "lineNumber": 300,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Chivo",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque", "headlines"],
    "lineNumber": 301,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chivo Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 302,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Chocolate Classical Sans",
    "variants": ["regular"],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 303,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Chokokutai",
    "variants": ["regular"],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["japanese", "funky"],
    "lineNumber": 304,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Chonburi",
    "variants": ["regular"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wide", "high contrast"],
    "lineNumber": 305,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cinzel",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["caps"],
    "lineNumber": 306,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cinzel Decorative",
    "variants": ["regular", "700", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["caps", "roman", "ancient"],
    "lineNumber": 307,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Clicker Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1960s", "script"],
    "lineNumber": 308,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Climate Crisis",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "block", "round", "headlines"],
    "lineNumber": 309,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Coda",
    "variants": ["regular", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["code", "large sizes"],
    "lineNumber": 310,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Codystar",
    "variants": ["300", "regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["dotted"],
    "lineNumber": 311,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Coiny",
    "variants": ["regular"],
    "subsets": ["tamil", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 312,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Combo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["marker", "fun", "headlines"],
    "lineNumber": 313,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Comfortaa",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["round", "geometric", "large sizes"],
    "lineNumber": 314,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Comforter",
    "variants": ["regular"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "cursive", "elegant"],
    "lineNumber": 315,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Comforter Brush",
    "variants": ["regular"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "cursive", "elegant"],
    "lineNumber": 316,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Comic Neue",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["comic"],
    "lineNumber": 317,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Coming Soon",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["scrawl", "kids"],
    "lineNumber": 318,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Comme",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 319,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Commissioner",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 320,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Concert One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 321,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Condiment",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "slanted"],
    "lineNumber": 322,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Content",
    "variants": ["regular", "700"],
    "subsets": ["khmer"],
    "category": "display",
    "tags": ["cambodian", "khmer"],
    "lineNumber": 323,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Contrail One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["slanted", "large sizes", "low contrast", "headlines"],
    "lineNumber": 324,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Convergence",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "low contrast"],
    "lineNumber": 325,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cookie",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "1950s", "brush", "calligraphic"],
    "lineNumber": 326,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Copse",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 327,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Corben",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 328,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Corinthia",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "romantic"],
    "lineNumber": 329,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cormorant",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["light"],
    "lineNumber": 330,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cormorant Garamond",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 331,
    "variable": false,
    "lastModified": "2025-03-05"
  },
  {
    "family": "Cormorant Infant",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["celtic"],
    "lineNumber": 332,
    "variable": false,
    "lastModified": "2025-03-05"
  },
  {
    "family": "Cormorant SC",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 333,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cormorant Unicase",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["celtic", "unicase"],
    "lineNumber": 334,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cormorant Upright",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["celtic"],
    "lineNumber": 335,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Courgette",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 336,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Courier Prime",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["low contrast", "monolinear", "typewriter"],
    "lineNumber": 337,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cousine",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 338,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Coustard",
    "variants": ["regular", "900"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["friendly"],
    "lineNumber": 339,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Covered By Your Grace",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["marker"],
    "lineNumber": 340,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Crafty Girls",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["bubbly"],
    "lineNumber": 341,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Creepster",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["halloween"],
    "lineNumber": 342,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Crete Round",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "low contrast", "round"],
    "lineNumber": 343,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Crimson Pro",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 344,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Crimson Text",
    "variants": ["regular", "italic", "600", "600italic", "700", "700italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 345,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Croissant One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["french"],
    "lineNumber": 346,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Crushed",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["condensed", "unicase"],
    "lineNumber": 347,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Cuprum",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed", "grotesque"],
    "lineNumber": 348,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cute Font",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 349,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Cutive",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["typewriter"],
    "lineNumber": 350,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Cutive Mono",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["typewriter", "code"],
    "lineNumber": 351,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DM Mono",
    "variants": ["300", "300italic", "regular", "italic", "500", "500italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 352,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DM Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 353,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DM Serif Display",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast", "transitional"],
    "lineNumber": 354,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DM Serif Text",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["geometric", "low contrast"],
    "lineNumber": 355,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dai Banna SIL",
    "variants": [
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["new-tai-lue", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 356,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Damion",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 357,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dancing Script",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1950s", "script"],
    "lineNumber": 358,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Danfo",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["groovy"],
    "lineNumber": 359,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Dangrek",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 360,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Darker Grotesque",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["light"],
    "lineNumber": 361,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Darumadrop One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["handwritten"],
    "lineNumber": 362,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "David Libre",
    "variants": ["regular", "500", "700"],
    "subsets": [
      "hebrew",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["hebrew"],
    "lineNumber": 363,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dawning of a New Day",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 364,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Days One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["heavy", "headlines"],
    "lineNumber": 365,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dekko",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["kids"],
    "lineNumber": 366,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Dela Gothic One",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "greek",
      "japanese",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["heavy", "block", "wide"],
    "lineNumber": 367,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Delicious Handrawn",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 368,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Delius",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["round", "comic"],
    "lineNumber": 369,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Delius Swash Caps",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cute", "swash", "round"],
    "lineNumber": 370,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Delius Unicase",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["round", "comic", "unicase"],
    "lineNumber": 371,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Della Respira",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["art deco"],
    "lineNumber": 372,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Denk One",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["tapered"],
    "lineNumber": 373,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Devonshire",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 374,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dhurjati",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["headlines"],
    "lineNumber": 375,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Didact Gothic",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["literacy"],
    "lineNumber": 376,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Diphylleia",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["korean", "brush"],
    "lineNumber": 377,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Diplomata",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["wide", "headlines"],
    "lineNumber": 378,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Diplomata SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["small caps", "wide", "headlines"],
    "lineNumber": 379,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Do Hyeon",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": ["korean"],
    "lineNumber": 380,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Dokdo",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["uneven"],
    "lineNumber": 381,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Domine",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 382,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Donegal One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["legibility"],
    "lineNumber": 383,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dongle",
    "variants": ["300", "regular", "700"],
    "subsets": ["korean", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 384,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Doppio One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 385,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dorsa",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 386,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dosis",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 387,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DotGothic16",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["pixel", "game"],
    "lineNumber": 388,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Doto",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["dotted", "code"],
    "lineNumber": 389,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Dr Sugiyama",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 390,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Duru Sans",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 391,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "DynaPuff",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["cyrillic-ext", "latin", "latin-ext"],
    "category": "display",
    "tags": ["playful"],
    "lineNumber": 392,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Dynalight",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cursive", "vintage"],
    "lineNumber": 393,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "EB Garamond",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["legibility"],
    "lineNumber": 394,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Eagle Lake",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "celtic"],
    "lineNumber": 395,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "East Sea Dokdo",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["uneven"],
    "lineNumber": 396,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Eater",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["halloween"],
    "lineNumber": 397,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Economica",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 398,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Eczar",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["devanagari", "greek", "greek-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 399,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Edu AU VIC WA NT Arrows",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 400,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Edu AU VIC WA NT Dots",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 401,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Edu AU VIC WA NT Guides",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 402,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Edu AU VIC WA NT Hand",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted", "literacy"],
    "lineNumber": 403,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Edu AU VIC WA NT Pre",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 404,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Edu NSW ACT Foundation",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 405,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Edu QLD Beginner",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 406,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Edu SA Beginner",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 407,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Edu TAS Beginner",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 408,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Edu VIC WA NT Beginner",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 409,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "El Messiri",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 410,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Electrolize",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["techno", "geometric", "square"],
    "lineNumber": 411,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Elsie",
    "variants": ["regular", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swirl"],
    "lineNumber": 412,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Elsie Swash Caps",
    "variants": ["regular", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swash"],
    "lineNumber": 413,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Emblema One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil", "slanted", "large sizes"],
    "lineNumber": 414,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Emilys Candy",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cute", "love", "hearts"],
    "lineNumber": 415,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "humanist", "geometric"],
    "lineNumber": 416,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 417,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans Expanded",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["expanded"],
    "lineNumber": 418,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans SC",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["caps", "small caps"],
    "lineNumber": 419,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans Semi Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 420,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Encode Sans Semi Expanded",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["expanded"],
    "lineNumber": 421,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Engagement",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "cursive", "vintage"],
    "lineNumber": 422,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Englebert",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["1930s", "playful"],
    "lineNumber": 423,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Enriqueta",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "egyptian"],
    "lineNumber": 424,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ephesis",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 425,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Epilogue",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 426,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Erica One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fat"],
    "lineNumber": 427,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Esteban",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["literature"],
    "lineNumber": 428,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Estonia",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 429,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Euphoria Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "copperplate", "script"],
    "lineNumber": 430,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ewert",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["western", "outline", "decorative"],
    "lineNumber": 431,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Exo",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "techno"],
    "lineNumber": 432,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Exo 2",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "techno"],
    "lineNumber": 433,
    "variable": true,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Expletus Sans",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil"],
    "lineNumber": 434,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Explora",
    "variants": ["regular"],
    "subsets": ["cherokee", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["light", "slanted"],
    "lineNumber": 435,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Faculty Glyphic",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 436,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Fahkwang",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["high contrast"],
    "lineNumber": 437,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Familjen Grotesk",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 438,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fanwood Text",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["1940s"],
    "lineNumber": 439,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Farro",
    "variants": ["300", "regular", "500", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 440,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Farsan",
    "variants": ["regular"],
    "subsets": ["gujarati", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 441,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fascinate",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["art deco", "high contrast"],
    "lineNumber": 442,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Fascinate Inline",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["art deco", "high contrast"],
    "lineNumber": 443,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Faster One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["lines", "speed", "headlines"],
    "lineNumber": 444,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fasthand",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["monolinear", "slanted", "friendly"],
    "lineNumber": 445,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Fauna One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["headlines"],
    "lineNumber": 446,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Faustina",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 447,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Federant",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["antiqua"],
    "lineNumber": 448,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Federo",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["art deco", "1900s"],
    "lineNumber": 449,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Felipa",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 450,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fenix",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 451,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Festive",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["decorative", "fun", "swirl", "whimsical"],
    "lineNumber": 452,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Figtree",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 453,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Finger Paint",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["brush", "kids"],
    "lineNumber": 454,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Finlandica",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 455,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Fira Code",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 456,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fira Mono",
    "variants": ["regular", "500", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 457,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fira Sans",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 458,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fira Sans Condensed",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 459,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fira Sans Extra Condensed",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 460,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fjalla One",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow"],
    "lineNumber": 461,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fjord One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["low contrast"],
    "lineNumber": 462,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Flamenco",
    "variants": ["300", "regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["slab", "monolinear"],
    "lineNumber": 463,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Flavors",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "spicy"],
    "lineNumber": 464,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fleur De Leah",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "elegant", "formal", "decorative"],
    "lineNumber": 465,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Flow Block",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wireframe"],
    "lineNumber": 466,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Flow Circular",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wireframe"],
    "lineNumber": 467,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Flow Rounded",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wireframe", "round"],
    "lineNumber": 468,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Foldit",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["color"],
    "lineNumber": 469,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Fondamento",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 470,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fontdiner Swanky",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["1950s", "funky", "uneven"],
    "lineNumber": 471,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Forum",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "display",
    "tags": ["roman"],
    "lineNumber": 472,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fragment Mono",
    "variants": ["regular", "italic"],
    "subsets": ["cyrillic-ext", "latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 473,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Francois One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["gothic"],
    "lineNumber": 474,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Frank Ruhl Libre",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 475,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fraunces",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 476,
    "variable": true,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Freckle Face",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["kids"],
    "lineNumber": 477,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fredericka the Great",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["sketch"],
    "lineNumber": 478,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fredoka",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 479,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Freehand",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["monolinear", "slanted"],
    "lineNumber": 480,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Freeman",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "gothic"],
    "lineNumber": 481,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fresca",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "comic"],
    "lineNumber": 482,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Frijole",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun", "spicy"],
    "lineNumber": 483,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fruktur",
    "variants": ["regular", "italic"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fraktur", "large sizes", "blackletter"],
    "lineNumber": 484,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fugaz One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["geometric", "slanted"],
    "lineNumber": 485,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fuggles",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl", "handwritten"],
    "lineNumber": 486,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Funnel Display",
    "variants": ["300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 487,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Funnel Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 488,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Fustat",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 489,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Fuzzy Bubbles",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cute", "kids", "playful", "handwritten"],
    "lineNumber": 490,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "GFS Didot",
    "variants": ["regular"],
    "subsets": ["greek"],
    "category": "serif",
    "tags": ["neoclassical"],
    "lineNumber": 491,
    "variable": false,
    "lastModified": "2024-04-12"
  },
  {
    "family": "GFS Neohellenic",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["greek"],
    "category": "sans-serif",
    "tags": ["round", "monolinear", "1930s"],
    "lineNumber": 492,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Ga Maamli",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["marker", "caps", "heavy", "handwritten"],
    "lineNumber": 493,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Gabarito",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric"],
    "lineNumber": 494,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gabriela",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["curls"],
    "lineNumber": 495,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gaegu",
    "variants": ["300", "regular", "700"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["korean", "handwritten"],
    "lineNumber": 496,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Gafata",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 497,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gajraj One",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "futuristic", "techno", "square"],
    "lineNumber": 498,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Galada",
    "variants": ["regular"],
    "subsets": ["bengali", "latin"],
    "category": "display",
    "tags": ["cursive"],
    "lineNumber": 499,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Galdeano",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 500,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Galindo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 501,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gamja Flower",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["korean", "handwritten"],
    "lineNumber": 502,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Gantari",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 503,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gasoek One",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["korean", "fat"],
    "lineNumber": 504,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Gayathri",
    "variants": ["100", "regular", "700"],
    "subsets": ["malayalam", "latin"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 505,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Geist",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 506,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Geist Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 507,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Gelasio",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 508,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gemunu Libre",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "techno", "square"],
    "lineNumber": 509,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Genos",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cherokee", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "square"],
    "lineNumber": 510,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gentium Book Plus",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 511,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gentium Plus",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 512,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Geo",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["square", "geometric"],
    "lineNumber": 513,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Geologica",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 514,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Georama",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 515,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Geostar",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["square", "outline", "headlines"],
    "lineNumber": 516,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Geostar Fill",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["square", "headlines"],
    "lineNumber": 517,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Germania One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fraktur", "geometric"],
    "lineNumber": 518,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gideon Roman",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["roman"],
    "lineNumber": 519,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gidole",
    "variants": ["regular"],
    "subsets": ["cyrillic", "greek", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 520,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Gidugu",
    "variants": ["regular"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["headlines"],
    "lineNumber": 521,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Gilda Display",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["headlines"],
    "lineNumber": 522,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Girassol",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["caps"],
    "lineNumber": 523,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Give You Glory",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl", "handwritten"],
    "lineNumber": 524,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Glass Antiqua",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["retro", "antiqua"],
    "lineNumber": 525,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Glegoo",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "hinted"],
    "lineNumber": 526,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gloock",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 527,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gloria Hallelujah",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl", "comic"],
    "lineNumber": 528,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Glory",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 529,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gluten",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["round", "fun"],
    "lineNumber": 530,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Goblin One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["wide", "headlines"],
    "lineNumber": 531,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gochi Hand",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["scrawl", "cartoon"],
    "lineNumber": 532,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Goldman",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic", "techno", "square"],
    "lineNumber": 533,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Golos Text",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 534,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gorditas",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["love", "cute", "hearts"],
    "lineNumber": 535,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gothic A1",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "korean",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["korean"],
    "lineNumber": 536,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Gotu",
    "variants": ["regular"],
    "subsets": ["devanagari", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 537,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Goudy Bookletter 1911",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 538,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gowun Batang",
    "variants": ["regular", "700"],
    "subsets": ["korean", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 539,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Gowun Dodum",
    "variants": ["regular"],
    "subsets": ["korean", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["korean"],
    "lineNumber": 540,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Graduate",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["collegiate", "block"],
    "lineNumber": 541,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grand Hotel",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1930s"],
    "lineNumber": 542,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grandiflora One",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["korean", "connected"],
    "lineNumber": 543,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grandstander",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["kids", "round", "friendly"],
    "lineNumber": 544,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Grape Nuts",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten", "light", "slanted"],
    "lineNumber": 545,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gravitas One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["heavy", "fat face", "large sizes"],
    "lineNumber": 546,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Great Vibes",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "handwriting",
    "tags": ["cursive", "elegant"],
    "lineNumber": 547,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grechen Fuemen",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["playful", "uneven"],
    "lineNumber": 548,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grenze",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["roman", "blackletter"],
    "lineNumber": 549,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grenze Gotisch",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["roman", "blackletter"],
    "lineNumber": 550,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Grey Qo",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "cursive", "elegant", "light"],
    "lineNumber": 551,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Griffy",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swirl", "whimsical"],
    "lineNumber": 552,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gruppo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin"],
    "lineNumber": 553,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gudea",
    "variants": ["regular", "italic", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 554,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gugi",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["korean", "futuristic"],
    "lineNumber": 555,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Gulzar",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 556,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Gupter",
    "variants": ["regular", "500", "700"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["compact"],
    "lineNumber": 557,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Gurajada",
    "variants": ["regular"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small sizes"],
    "lineNumber": 558,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Gwendolyn",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "light", "romantic"],
    "lineNumber": 559,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Habibi",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 560,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hachi Maru Pop",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["1970s", "1980s", "cute", "japanese"],
    "lineNumber": 561,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hahmlet",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["korean", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["korean"],
    "lineNumber": 562,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Halant",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 563,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hammersmith One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 564,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hanalei",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "bamboo"],
    "lineNumber": 565,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hanalei Fill",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "bamboo"],
    "lineNumber": 566,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Handjet",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "arabic",
      "armenian",
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["pixel"],
    "lineNumber": 567,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Handlee",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["small sizes"],
    "lineNumber": 568,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hanken Grotesk",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 569,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hanuman",
    "variants": ["100", "300", "regular", "700", "900"],
    "subsets": ["khmer", "latin"],
    "category": "serif",
    "tags": ["tall"],
    "lineNumber": 570,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Happy Monkey",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round", "headlines", "fun"],
    "lineNumber": 571,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Harmattan",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 572,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Headland One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 573,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hedvig Letters Sans",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 574,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hedvig Letters Serif",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 575,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Heebo",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["hebrew", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["hebrew"],
    "lineNumber": 576,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Henny Penny",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["whimsical", "uneven"],
    "lineNumber": 577,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hepta Slab",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 578,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Herr Von Muellerhoff",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "formal"],
    "lineNumber": 579,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hi Melody",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["cute", "korean"],
    "lineNumber": 580,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Hina Mincho",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 581,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hind",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "monolinear"],
    "lineNumber": 582,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hind Guntur",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 583,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hind Madurai",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 584,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Hind Mysuru",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 585,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hind Siliguri",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 586,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hind Vadodara",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 587,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Holtwood One SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps", "heavy", "block"],
    "lineNumber": 588,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Homemade Apple",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 589,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Homenaje",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["condensed", "grotesque"],
    "lineNumber": 590,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Honk",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["color", "shadow"],
    "lineNumber": 591,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Host Grotesk",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 592,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hubballi",
    "variants": ["regular"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 593,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hubot Sans",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 594,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Hurricane",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 595,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Mono",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["grotesque", "code"],
    "lineNumber": 596,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 597,
    "variable": false,
    "lastModified": "2025-02-05"
  },
  {
    "family": "IBM Plex Sans Arabic",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["arabic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 598,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "IBM Plex Sans Condensed",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque", "condensed"],
    "lineNumber": 599,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans Devanagari",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic-ext", "devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 600,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans Hebrew",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["hebrew"],
    "lineNumber": 601,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans JP",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 602,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans KR",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["korean"],
    "lineNumber": 603,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "IBM Plex Sans Thai",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic-ext", "thai", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 604,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Sans Thai Looped",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic-ext", "thai", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 605,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IBM Plex Serif",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["grotesque"],
    "lineNumber": 606,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell DW Pica",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["grunge"],
    "lineNumber": 607,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell DW Pica SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps", "grunge"],
    "lineNumber": 608,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell Double Pica",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 609,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell Double Pica SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 610,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell English",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["typewriter"],
    "lineNumber": 611,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell English SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps", "typewriter"],
    "lineNumber": 612,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell French Canon",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 613,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell French Canon SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 614,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell Great Primer",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 615,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "IM Fell Great Primer SC",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 616,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Iansui",
    "variants": ["regular"],
    "subsets": ["chinese-hongkong", "symbols2", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 617,
    "variable": false,
    "lastModified": "2025-03-04"
  },
  {
    "family": "Ibarra Real Nova",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 618,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Iceberg",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["code", "techno", "monolinear", "large sizes"],
    "lineNumber": 619,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Iceland",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["1950s", "square", "eurostile"],
    "lineNumber": 620,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Imbue",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["condensed", "compact"],
    "lineNumber": 621,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Imperial Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "elegant"],
    "lineNumber": 622,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Imprima",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 623,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inclusive Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 624,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Inconsolata",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 625,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inder",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["art nouveau", "low contrast"],
    "lineNumber": 626,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Indie Flower",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["bubbly", "scrawl"],
    "lineNumber": 627,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Ingrid Darling",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "cute", "playful", "swirl", "whimsical"],
    "lineNumber": 628,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inika",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 629,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inknut Antiqua",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["literature", "antiqua"],
    "lineNumber": 630,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Inria Sans",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "modern"],
    "lineNumber": 631,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Inria Serif",
    "variants": ["300", "300italic", "regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 632,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Inspiration",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["fun", "script"],
    "lineNumber": 633,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Instrument Sans",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque", "legibility"],
    "lineNumber": 634,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Instrument Serif",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["condensed"],
    "lineNumber": 635,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inter",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 636,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Inter Tight",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 637,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Irish Grover",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun", "uneven"],
    "lineNumber": 638,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Island Moments",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 639,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Istok Web",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["hinted"],
    "lineNumber": 640,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Italiana",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["thin", "didone", "headlines"],
    "lineNumber": 641,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Italianno",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "formal"],
    "lineNumber": 642,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Itim",
    "variants": ["regular"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["round", "friendly"],
    "lineNumber": 643,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jacquard 12",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["knit"],
    "lineNumber": 644,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacquard 12 Charted",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["knit"],
    "lineNumber": 645,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacquard 24",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit"],
    "lineNumber": 646,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacquard 24 Charted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit"],
    "lineNumber": 647,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacquarda Bastarda 9",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["needlepoint", "pixel"],
    "lineNumber": 648,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacquarda Bastarda 9 Charted",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["needlepoint"],
    "lineNumber": 649,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jacques Francois",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["1700s"],
    "lineNumber": 650,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jacques Francois Shadow",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["outline", "shadow", "1700s", "large sizes"],
    "lineNumber": 651,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jaini",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 652,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jaini Purva",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 653,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jaldi",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 654,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jaro",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy", "techno"],
    "lineNumber": 655,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jersey 10",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 656,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 10 Charted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 657,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 15",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 658,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 15 Charted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 659,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 20",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 660,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 20 Charted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 661,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 25",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 662,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Jersey 25 Charted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "sport"],
    "lineNumber": 663,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "JetBrains Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 664,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Jim Nightshade",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 665,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Joan",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 666,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jockey One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["block"],
    "lineNumber": 667,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jolly Lodger",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1950s", "uneven"],
    "lineNumber": 668,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jomhuria",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["arabic", "heavy"],
    "lineNumber": 669,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Jomolhari",
    "variants": ["regular"],
    "subsets": ["tibetan", "latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 670,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Josefin Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin", "geometric"],
    "lineNumber": 671,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Josefin Slab",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab", "thin"],
    "lineNumber": 672,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Jost",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 673,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Joti One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "cartoon", "headlines"],
    "lineNumber": 674,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jua",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": ["korean", "round"],
    "lineNumber": 675,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Judson",
    "variants": ["regular", "italic", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["literacy"],
    "lineNumber": 676,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Julee",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 677,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Julius Sans One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["caps", "thin"],
    "lineNumber": 678,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Junge",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["elegant"],
    "lineNumber": 679,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Jura",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "kayah-li",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["eurostile"],
    "lineNumber": 680,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Just Another Hand",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["marker", "brush"],
    "lineNumber": 681,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Just Me Again Down Here",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 682,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "K2D",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 683,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kablammo",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["1990s", "cartoon", "fun", "caps"],
    "lineNumber": 684,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kadwa",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["humanist"],
    "lineNumber": 685,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Kaisei Decol",
    "variants": ["regular", "500", "700"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 686,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Kaisei HarunoUmi",
    "variants": ["regular", "500", "700"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 687,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Kaisei Opti",
    "variants": ["regular", "500", "700"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 688,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Kaisei Tokumin",
    "variants": ["regular", "500", "700", "800"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 689,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Kalam",
    "variants": ["300", "regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 690,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Kalnia",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["math", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 691,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Kalnia Glaze",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["gradient", "color", "outline"],
    "lineNumber": 692,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Kameron",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "egyptian"],
    "lineNumber": 693,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kanit",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "futuristic", "loopless"],
    "lineNumber": 694,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kantumruy Pro",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["khmer", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 695,
    "variable": true,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Karantina",
    "variants": ["300", "regular", "700"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["condensed", "hebrew"],
    "lineNumber": 696,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Karla",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 697,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Karla Tamil Inclined",
    "variants": ["regular", "700"],
    "subsets": ["tamil"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 698,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Karla Tamil Upright",
    "variants": ["regular", "700"],
    "subsets": ["tamil"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 699,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Karma",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 700,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Katibeh",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 701,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Kaushan Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "script"],
    "lineNumber": 702,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kavivanar",
    "variants": ["regular"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted", "calligraphic"],
    "lineNumber": 703,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kavoon",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 704,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kay Pho Du",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["kayah-li", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 705,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kdam Thmor Pro",
    "variants": ["regular"],
    "subsets": ["khmer", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["khmer", "square", "modern", "futuristic"],
    "lineNumber": 706,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Keania One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil"],
    "lineNumber": 707,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kelly Slab",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slab", "geometric", "1930s"],
    "lineNumber": 708,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kenia",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["stencil", "headlines"],
    "lineNumber": 709,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Khand",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 710,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Khmer",
    "variants": ["regular"],
    "subsets": ["khmer"],
    "category": "sans-serif",
    "tags": ["cambodian", "khmer"],
    "lineNumber": 711,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Khula",
    "variants": ["300", "regular", "600", "700", "800"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 712,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Kings",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "blackletter"],
    "lineNumber": 713,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kirang Haerang",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["korean", "uneven"],
    "lineNumber": 714,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Kite One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear", "slanted", "whimsical"],
    "lineNumber": 715,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kiwi Maru",
    "variants": ["300", "regular", "500"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["cute"],
    "lineNumber": 716,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Klee One",
    "variants": ["regular", "600"],
    "subsets": ["cyrillic", "greek-ext", "japanese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["friendly", "round"],
    "lineNumber": 717,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Knewave",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 718,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "KoHo",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 719,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kodchasan",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["kids"],
    "lineNumber": 720,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kode Mono",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 721,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Koh Santepheap",
    "variants": ["100", "300", "regular", "700", "900"],
    "subsets": ["khmer", "latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 722,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Kolker Brush",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 723,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Konkhmer Sleokchher",
    "variants": ["regular"],
    "subsets": ["khmer", "latin", "latin-ext"],
    "category": "display",
    "tags": ["brush", "decorative"],
    "lineNumber": 724,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kosugi",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 725,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Kosugi Maru",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 726,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Kotta One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slanted", "geometric", "modern"],
    "lineNumber": 727,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Koulen",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["square", "heavy"],
    "lineNumber": 728,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Kranky",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["outline"],
    "lineNumber": 729,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kreon",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["low contrast"],
    "lineNumber": 730,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kristi",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "scrawl"],
    "lineNumber": 731,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Krona One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide"],
    "lineNumber": 732,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Krub",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 733,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kufam",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern", "arabic"],
    "lineNumber": 734,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kulim Park",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 735,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kumar One",
    "variants": ["regular"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic", "geometric", "wide"],
    "lineNumber": 736,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Kumar One Outline",
    "variants": ["regular"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline"],
    "lineNumber": 737,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kumbh Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 738,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Kurale",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["curls", "headlines"],
    "lineNumber": 739,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "LXGW WenKai Mono TC",
    "variants": ["300", "regular", "700"],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "lisu",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": [],
    "lineNumber": 740,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "LXGW WenKai TC",
    "variants": ["300", "regular", "700"],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "lisu",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 741,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "La Belle Aurore",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "love"],
    "lineNumber": 742,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Labrada",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 743,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lacquer",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["caps", "handwritten"],
    "lineNumber": 744,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Laila",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 745,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Lakki Reddy",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "handwriting",
    "tags": ["uneven"],
    "lineNumber": 746,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Lalezar",
    "variants": ["regular"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "1960s", "1970s"],
    "lineNumber": 747,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lancelot",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swash", "french"],
    "lineNumber": 748,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Langar",
    "variants": ["regular"],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 749,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lateef",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 750,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Lato",
    "variants": [
      "100",
      "100italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "modern"],
    "lineNumber": 751,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lavishly Yours",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "romantic"],
    "lineNumber": 752,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "League Gothic",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["gothic", "condensed"],
    "lineNumber": 753,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "League Script",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "1920s", "love", "script"],
    "lineNumber": 754,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "League Spartan",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 755,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Leckerli One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 756,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ledger",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 757,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lekton",
    "variants": ["regular", "italic", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["typewriter", "code"],
    "lineNumber": 758,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lemon",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "brush"],
    "lineNumber": 759,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lemonada",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 760,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lexend",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 761,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Deca",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 762,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Exa",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 763,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Giga",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 764,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Mega",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide", "legibility"],
    "lineNumber": 765,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Peta",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide", "legibility"],
    "lineNumber": 766,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Tera",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide", "legibility"],
    "lineNumber": 767,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Lexend Zetta",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide", "legibility"],
    "lineNumber": 768,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Libre Barcode 128",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 769,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode 128 Text",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 770,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode 39",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 771,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode 39 Extended",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 772,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode 39 Extended Text",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 773,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode 39 Text",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 774,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Barcode EAN13 Text",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["barcode"],
    "lineNumber": 775,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Libre Baskerville",
    "variants": ["regular", "italic", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 776,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Libre Bodoni",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 777,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Libre Caslon Display",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["1950s"],
    "lineNumber": 778,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Libre Caslon Text",
    "variants": ["regular", "italic", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["1950s"],
    "lineNumber": 779,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Libre Franklin",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["1900s"],
    "lineNumber": 780,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Licorice",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["playful"],
    "lineNumber": 781,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Life Savers",
    "variants": ["regular", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1950s"],
    "lineNumber": 782,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lilita One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "headlines"],
    "lineNumber": 783,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lily Script One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cute", "script"],
    "lineNumber": 784,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Limelight",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1920s", "art deco", "high contrast"],
    "lineNumber": 785,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Linden Hill",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["1920s"],
    "lineNumber": 786,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Linefont",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["lines", "diagram"],
    "lineNumber": 787,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Lisu Bosa",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["lisu", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 788,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Liter",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 789,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Literata",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 790,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Liu Jian Mao Cao",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "handwriting",
    "tags": ["scrawl", "handwritten"],
    "lineNumber": 791,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Livvic",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "900",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly"],
    "lineNumber": 792,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lobster",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 793,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lobster Two",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 794,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Londrina Outline",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun", "outline", "handwritten"],
    "lineNumber": 795,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Londrina Shadow",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["outline", "shadow", "handwritten"],
    "lineNumber": 796,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Londrina Sketch",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["outline", "sketch", "handwritten"],
    "lineNumber": 797,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Londrina Solid",
    "variants": ["100", "300", "regular", "900"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun", "block", "handwritten"],
    "lineNumber": 798,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Long Cang",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 799,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Lora",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 800,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Love Light",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["romantic", "hearts"],
    "lineNumber": 801,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Love Ya Like A Sister",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["kids"],
    "lineNumber": 802,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Loved by the King",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 803,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Lovers Quarrel",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "decorative"],
    "lineNumber": 804,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Luckiest Guy",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cartoon", "heavy", "1950s"],
    "lineNumber": 805,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Lugrasimo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted", "formal"],
    "lineNumber": 806,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lumanosimo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 807,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lunasima",
    "variants": ["regular", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 808,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lusitana",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 809,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Lustria",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["round"],
    "lineNumber": 810,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Luxurious Roman",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["roman"],
    "lineNumber": 811,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Luxurious Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "slanted"],
    "lineNumber": 812,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "M PLUS 1",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 813,
    "variable": true,
    "lastModified": "2024-11-07"
  },
  {
    "family": "M PLUS 1 Code",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code", "japanese"],
    "lineNumber": 814,
    "variable": true,
    "lastModified": "2024-11-07"
  },
  {
    "family": "M PLUS 1p",
    "variants": ["100", "300", "regular", "500", "700", "800", "900"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "japanese",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 815,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "M PLUS 2",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 816,
    "variable": true,
    "lastModified": "2024-11-07"
  },
  {
    "family": "M PLUS Code Latin",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["code"],
    "lineNumber": 817,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "M PLUS Rounded 1c",
    "variants": ["100", "300", "regular", "500", "700", "800", "900"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "japanese",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 818,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ma Shan Zheng",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "handwriting",
    "tags": ["heavy"],
    "lineNumber": 819,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Macondo",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 820,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Macondo Swash Caps",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["swash", "fun"],
    "lineNumber": 821,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mada",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 822,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Madimi One",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "geometric"],
    "lineNumber": 823,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Magra",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 824,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Maiden Orange",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["1950s", "retro"],
    "lineNumber": 825,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Maitree",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["legibility"],
    "lineNumber": 826,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Major Mono Display",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["uneven", "caps", "geometric"],
    "lineNumber": 827,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mako",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 828,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mali",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["kids"],
    "lineNumber": 829,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mallanna",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 830,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Maname",
    "variants": ["regular"],
    "subsets": ["sinhala", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 831,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Mandali",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 832,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Manjari",
    "variants": ["100", "regular", "700"],
    "subsets": ["malayalam", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 833,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Manrope",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 834,
    "variable": true,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Mansalva",
    "variants": ["regular"],
    "subsets": ["greek", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 835,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Manuale",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 836,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marcellus",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["roman"],
    "lineNumber": 837,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marcellus SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps", "roman"],
    "lineNumber": 838,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marck Script",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["marker", "script"],
    "lineNumber": 839,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Margarine",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fuzzy"],
    "lineNumber": 840,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marhey",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["arabic", "marker"],
    "lineNumber": 841,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Markazi Text",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 842,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Marko One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["kids", "whimsical", "literature", "large sizes", "headlines"],
    "lineNumber": 843,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marmelad",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "headlines"],
    "lineNumber": 844,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Martel",
    "variants": ["200", "300", "regular", "600", "700", "800", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 845,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Martel Sans",
    "variants": ["200", "300", "regular", "600", "700", "800", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 846,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Martian Mono",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 847,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Marvel",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["condensed", "headlines"],
    "lineNumber": 848,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mate",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["vintage"],
    "lineNumber": 849,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mate SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 850,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Matemasie",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["fat", "cute", "round", "cartoon"],
    "lineNumber": 851,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Material Icons",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["icons"],
    "lineNumber": 852,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Material Icons Outlined",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["outline", "icons"],
    "lineNumber": 853,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Material Icons Round",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["round", "icons"],
    "lineNumber": 854,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Material Icons Sharp",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["icons"],
    "lineNumber": 855,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Material Icons Two Tone",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["icons"],
    "lineNumber": 856,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Material Symbols",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 857,
    "variable": false,
    "lastModified": "2025-03-20"
  },
  {
    "family": "Material Symbols Outlined",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["outline", "icons"],
    "lineNumber": 858,
    "variable": false,
    "lastModified": "2025-03-20"
  },
  {
    "family": "Material Symbols Rounded",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["round", "icons"],
    "lineNumber": 859,
    "variable": false,
    "lastModified": "2025-03-20"
  },
  {
    "family": "Material Symbols Sharp",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["icons"],
    "lineNumber": 860,
    "variable": false,
    "lastModified": "2025-03-20"
  },
  {
    "family": "Maven Pro",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 861,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "McLaren",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["comic"],
    "lineNumber": 862,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mea Culpa",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "cursive", "elegant"],
    "lineNumber": 863,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Meddon",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1700s"],
    "lineNumber": 864,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "MedievalSharp",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["engraved"],
    "lineNumber": 865,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Medula One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["condensed", "brush"],
    "lineNumber": 866,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Meera Inimai",
    "variants": ["regular"],
    "subsets": ["tamil", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 867,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Megrim",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic"],
    "lineNumber": 868,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Meie Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["vintage", "cursive", "script"],
    "lineNumber": 869,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Meow Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "fun"],
    "lineNumber": 870,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Merienda",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "headlines"],
    "lineNumber": 871,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Merriweather",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 872,
    "variable": false,
    "lastModified": "2025-03-05"
  },
  {
    "family": "Merriweather Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast", "condensed"],
    "lineNumber": 873,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Metal",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 874,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Metal Mania",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["rock"],
    "lineNumber": 875,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Metamorphous",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["roman", "gothic"],
    "lineNumber": 876,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Metrophobic",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 877,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Michroma",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "eurostile"],
    "lineNumber": 878,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Micro 5",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["small sizes"],
    "lineNumber": 879,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Micro 5 Charted",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["knit", "small sizes"],
    "lineNumber": 880,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Milonga",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical", "headlines"],
    "lineNumber": 881,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Miltonian",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["outline", "tattoo"],
    "lineNumber": 882,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Miltonian Tattoo",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["tattoo"],
    "lineNumber": 883,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mina",
    "variants": ["regular", "700"],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "small sizes"],
    "lineNumber": 884,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mingzat",
    "variants": ["regular"],
    "subsets": ["lepcha", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 885,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Miniver",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["cursive", "playful"],
    "lineNumber": 886,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Miriam Libre",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 887,
    "variable": false,
    "lastModified": "2024-10-29"
  },
  {
    "family": "Mirza",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 888,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Miss Fajardose",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "whimsical", "love"],
    "lineNumber": 889,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mitr",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 890,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mochiy Pop One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin"],
    "category": "sans-serif",
    "tags": ["japanese", "gothic", "cute", "manga"],
    "lineNumber": 891,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Mochiy Pop P One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin"],
    "category": "sans-serif",
    "tags": ["japanese", "gothic", "cute", "signage", "manga"],
    "lineNumber": 892,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Modak",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "fat"],
    "lineNumber": 893,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Modern Antiqua",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical", "antiqua"],
    "lineNumber": 894,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Moderustic",
    "variants": ["300", "regular", "500", "600", "700", "800"],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 895,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mogra",
    "variants": ["regular"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted", "marker"],
    "lineNumber": 896,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Mohave",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow"],
    "lineNumber": 897,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Moirai One",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "display",
    "tags": ["korean", "outline"],
    "lineNumber": 898,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Molengo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 899,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Molle",
    "variants": ["italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "heavy"],
    "lineNumber": 900,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mona Sans",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 901,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Monda",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 902,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Monofett",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 903,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Monomakh",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 904,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Monomaniac One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "heavy", "japanese"],
    "lineNumber": 905,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Monoton",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "lines", "outline"],
    "lineNumber": 906,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Monsieur La Doulaise",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "formal", "love"],
    "lineNumber": 907,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Montaga",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 908,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Montagu Slab",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 909,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "MonteCarlo",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "formal", "elegant"],
    "lineNumber": 910,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Montez",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["1960s", "elegant"],
    "lineNumber": 911,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Montserrat",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["signage", "urban", "vintage"],
    "lineNumber": 912,
    "variable": true,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Montserrat Alternates",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["signage", "urban", "vintage"],
    "lineNumber": 913,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Montserrat Underline",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["connected"],
    "lineNumber": 914,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Moo Lah Lah",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["kids", "fun"],
    "lineNumber": 915,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mooli",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 916,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Moon Dance",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted", "calligraphic", "handwritten"],
    "lineNumber": 917,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Moul",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["calligraphic"],
    "lineNumber": 918,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Moulpali",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "sans-serif",
    "tags": ["cambodian", "khmer"],
    "lineNumber": 919,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Mountains of Christmas",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["whimsical", "uneven"],
    "lineNumber": 920,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mouse Memoirs",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["cartoon", "1950s", "1960s", "comic"],
    "lineNumber": 921,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mr Bedfort",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "brush"],
    "lineNumber": 922,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mr Dafoe",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "brush"],
    "lineNumber": 923,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mr De Haviland",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "love"],
    "lineNumber": 924,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mrs Saint Delafield",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "love"],
    "lineNumber": 925,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mrs Sheppards",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 926,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ms Madi",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["monolinear"],
    "lineNumber": 927,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mukta",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear", "humanist"],
    "lineNumber": 928,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Mukta Mahee",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 929,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mukta Malar",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 930,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mukta Vaani",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "monolinear"],
    "lineNumber": 931,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Mulish",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 932,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Murecho",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "japanese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 933,
    "variable": true,
    "lastModified": "2024-08-07"
  },
  {
    "family": "MuseoModerno",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric"],
    "lineNumber": 934,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "My Soul",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 935,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mynerve",
    "variants": ["regular"],
    "subsets": ["greek", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 936,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Mystery Quest",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1960s", "swirl"],
    "lineNumber": 937,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "NTR",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 938,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nabla",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "math", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["game", "square", "gradient", "color"],
    "lineNumber": 939,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Namdhinggo",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["limbu", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 940,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nanum Brush Script",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["korean", "brush"],
    "lineNumber": 941,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nanum Gothic",
    "variants": ["regular", "700", "800"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 942,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nanum Gothic Coding",
    "variants": ["regular", "700"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["korean", "code"],
    "lineNumber": 943,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Nanum Myeongjo",
    "variants": ["regular", "700", "800"],
    "subsets": ["korean", "latin"],
    "category": "serif",
    "tags": ["korean"],
    "lineNumber": 944,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Nanum Pen Script",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "handwriting",
    "tags": ["korean", "handwritten"],
    "lineNumber": 945,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Narnoor",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["gunjala-gondi", "math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 946,
    "variable": false,
    "lastModified": "2023-11-28"
  },
  {
    "family": "Neonderthaw",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "outline"],
    "lineNumber": 947,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nerko One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["marker", "friendly"],
    "lineNumber": 948,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Neucha",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin"],
    "category": "handwriting",
    "tags": ["fun"],
    "lineNumber": 949,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Neuton",
    "variants": ["200", "300", "regular", "italic", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 950,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "New Amsterdam",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 951,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "New Rocker",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["rock", "tattoo", "blackletter"],
    "lineNumber": 952,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "New Tegomin",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["typewriter"],
    "lineNumber": 953,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "News Cycle",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["gothic"],
    "lineNumber": 954,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Newsreader",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["legibility"],
    "lineNumber": 955,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Niconne",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 956,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Niramit",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 957,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nixie One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["typewriter"],
    "lineNumber": 958,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nobile",
    "variants": ["regular", "italic", "500", "500italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 959,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nokora",
    "variants": ["100", "300", "regular", "700", "900"],
    "subsets": ["khmer", "latin"],
    "category": "sans-serif",
    "tags": ["modern", "legibility"],
    "lineNumber": 960,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Norican",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 961,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nosifer",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["halloween"],
    "lineNumber": 962,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Notable",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["caps", "high contrast"],
    "lineNumber": 963,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nothing You Could Do",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["scrawl", "handwritten"],
    "lineNumber": 964,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noticia Text",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "humanist", "small sizes", "hinted"],
    "lineNumber": 965,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Color Emoji",
    "variants": ["regular"],
    "subsets": ["emoji"],
    "category": "sans-serif",
    "tags": ["color", "icons"],
    "lineNumber": 966,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Noto Emoji",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["emoji"],
    "category": "sans-serif",
    "tags": ["icons"],
    "lineNumber": 967,
    "variable": true,
    "lastModified": "2024-11-26"
  },
  {
    "family": "Noto Kufi Arabic",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["arabic", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 968,
    "variable": true,
    "lastModified": "2024-02-29"
  },
  {
    "family": "Noto Music",
    "variants": ["regular"],
    "subsets": ["music", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["icons"],
    "lineNumber": 969,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Naskh Arabic",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 970,
    "variable": true,
    "lastModified": "2024-04-12"
  },
  {
    "family": "Noto Nastaliq Urdu",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 971,
    "variable": true,
    "lastModified": "2023-03-21"
  },
  {
    "family": "Noto Rashi Hebrew",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["greek-ext", "hebrew", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["hebrew"],
    "lineNumber": 972,
    "variable": true,
    "lastModified": "2024-06-05"
  },
  {
    "family": "Noto Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "devanagari",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["hinted"],
    "lineNumber": 973,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Adlam",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["adlam", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 974,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Adlam Unjoined",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["adlam", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 975,
    "variable": true,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Anatolian Hieroglyphs",
    "variants": ["regular"],
    "subsets": ["anatolian-hieroglyphs", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 976,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Arabic",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["arabic", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 977,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Armenian",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["armenian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 978,
    "variable": true,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Sans Avestan",
    "variants": ["regular"],
    "subsets": ["avestan", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 979,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Balinese",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["balinese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 980,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Bamum",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["bamum", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 981,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Bassa Vah",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["bassa-vah", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 982,
    "variable": true,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Batak",
    "variants": ["regular"],
    "subsets": ["batak", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 983,
    "variable": false,
    "lastModified": "2024-02-29"
  },
  {
    "family": "Noto Sans Bengali",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 984,
    "variable": true,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Noto Sans Bhaiksuki",
    "variants": ["regular"],
    "subsets": ["bhaiksuki", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 985,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Brahmi",
    "variants": ["regular"],
    "subsets": ["brahmi", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 986,
    "variable": false,
    "lastModified": "2024-02-29"
  },
  {
    "family": "Noto Sans Buginese",
    "variants": ["regular"],
    "subsets": ["buginese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 987,
    "variable": false,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Sans Buhid",
    "variants": ["regular"],
    "subsets": ["buhid", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 988,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Canadian Aboriginal",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["canadian-aboriginal", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 989,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Carian",
    "variants": ["regular"],
    "subsets": ["carian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 990,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Caucasian Albanian",
    "variants": ["regular"],
    "subsets": ["caucasian-albanian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 991,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Chakma",
    "variants": ["regular"],
    "subsets": ["chakma", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 992,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Cham",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cham", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 993,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Cherokee",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cherokee", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 994,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Chorasmian",
    "variants": ["regular"],
    "subsets": ["chorasmian", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 995,
    "variable": false,
    "lastModified": "2024-01-26"
  },
  {
    "family": "Noto Sans Coptic",
    "variants": ["regular"],
    "subsets": ["coptic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 996,
    "variable": false,
    "lastModified": "2023-12-14"
  },
  {
    "family": "Noto Sans Cuneiform",
    "variants": ["regular"],
    "subsets": ["cuneiform", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 997,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Cypriot",
    "variants": ["regular"],
    "subsets": ["cypriot", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 998,
    "variable": false,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Sans Cypro Minoan",
    "variants": ["regular"],
    "subsets": ["cypro-minoan", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 999,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Sans Deseret",
    "variants": ["regular"],
    "subsets": ["deseret", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1000,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Devanagari",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1001,
    "variable": true,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Display",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1002,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Duployan",
    "variants": ["regular", "700"],
    "subsets": ["duployan", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1003,
    "variable": false,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Egyptian Hieroglyphs",
    "variants": ["regular"],
    "subsets": ["egyptian-hieroglyphs", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["egyptian"],
    "lineNumber": 1004,
    "variable": false,
    "lastModified": "2024-05-02"
  },
  {
    "family": "Noto Sans Elbasan",
    "variants": ["regular"],
    "subsets": ["elbasan", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1005,
    "variable": false,
    "lastModified": "2023-05-23"
  },
  {
    "family": "Noto Sans Elymaic",
    "variants": ["regular"],
    "subsets": ["elymaic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1006,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Ethiopic",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["ethiopic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1007,
    "variable": true,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Georgian",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic-ext",
      "georgian",
      "greek-ext",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1008,
    "variable": true,
    "lastModified": "2024-02-29"
  },
  {
    "family": "Noto Sans Glagolitic",
    "variants": ["regular"],
    "subsets": [
      "cyrillic-ext",
      "glagolitic",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1009,
    "variable": false,
    "lastModified": "2024-01-26"
  },
  {
    "family": "Noto Sans Gothic",
    "variants": ["regular"],
    "subsets": ["gothic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1010,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Grantha",
    "variants": ["regular"],
    "subsets": ["grantha", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1011,
    "variable": false,
    "lastModified": "2024-06-10"
  },
  {
    "family": "Noto Sans Gujarati",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["gujarati", "math", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1012,
    "variable": true,
    "lastModified": "2023-11-28"
  },
  {
    "family": "Noto Sans Gunjala Gondi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["gunjala-gondi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1013,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Gurmukhi",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1014,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans HK",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1015,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Hanifi Rohingya",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["hanifi-rohingya", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1016,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Hanunoo",
    "variants": ["regular"],
    "subsets": ["hanunoo", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1017,
    "variable": false,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Sans Hatran",
    "variants": ["regular"],
    "subsets": ["hatran", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1018,
    "variable": false,
    "lastModified": "2023-07-13"
  },
  {
    "family": "Noto Sans Hebrew",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cyrillic-ext", "greek-ext", "hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["hebrew"],
    "lineNumber": 1019,
    "variable": true,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Imperial Aramaic",
    "variants": ["regular"],
    "subsets": ["imperial-aramaic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1020,
    "variable": false,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Indic Siyaq Numbers",
    "variants": ["regular"],
    "subsets": ["indic-siyaq-numbers", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1021,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Sans Inscriptional Pahlavi",
    "variants": ["regular"],
    "subsets": ["inscriptional-pahlavi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1022,
    "variable": false,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans Inscriptional Parthian",
    "variants": ["regular"],
    "subsets": ["inscriptional-parthian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1023,
    "variable": false,
    "lastModified": "2024-07-01"
  },
  {
    "family": "Noto Sans JP",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cyrillic", "japanese", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1024,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Noto Sans Javanese",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["javanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1025,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans KR",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cyrillic", "korean", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1026,
    "variable": false,
    "lastModified": "2023-08-17"
  },
  {
    "family": "Noto Sans Kaithi",
    "variants": ["regular"],
    "subsets": ["kaithi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1027,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Kannada",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1028,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Kawi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["kawi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1029,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Kayah Li",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["kayah-li", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1030,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Kharoshthi",
    "variants": ["regular"],
    "subsets": ["kharoshthi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1031,
    "variable": false,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Khmer",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["khmer", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1032,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Khojki",
    "variants": ["regular"],
    "subsets": ["khojki", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1033,
    "variable": false,
    "lastModified": "2024-02-16"
  },
  {
    "family": "Noto Sans Khudawadi",
    "variants": ["regular"],
    "subsets": ["khudawadi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1034,
    "variable": false,
    "lastModified": "2024-08-21"
  },
  {
    "family": "Noto Sans Lao",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["lao", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1035,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Lao Looped",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["lao", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1036,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Lepcha",
    "variants": ["regular"],
    "subsets": ["lepcha", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1037,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Limbu",
    "variants": ["regular"],
    "subsets": ["limbu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1038,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Linear A",
    "variants": ["regular"],
    "subsets": ["linear-a", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1039,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Linear B",
    "variants": ["regular"],
    "subsets": ["linear-b", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1040,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Lisu",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["lisu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1041,
    "variable": true,
    "lastModified": "2023-03-09"
  },
  {
    "family": "Noto Sans Lycian",
    "variants": ["regular"],
    "subsets": ["lycian"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1042,
    "variable": false,
    "lastModified": "2022-05-10"
  },
  {
    "family": "Noto Sans Lydian",
    "variants": ["regular"],
    "subsets": ["lydian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1043,
    "variable": false,
    "lastModified": "2023-12-14"
  },
  {
    "family": "Noto Sans Mahajani",
    "variants": ["regular"],
    "subsets": ["mahajani", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1044,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Malayalam",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["malayalam", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1045,
    "variable": true,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Mandaic",
    "variants": ["regular"],
    "subsets": ["mandaic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1046,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Manichaean",
    "variants": ["regular"],
    "subsets": ["manichaean", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1047,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Marchen",
    "variants": ["regular"],
    "subsets": ["marchen", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1048,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Masaram Gondi",
    "variants": ["regular"],
    "subsets": ["masaram-gondi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1049,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Math",
    "variants": ["regular"],
    "subsets": ["math"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1050,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Sans Mayan Numerals",
    "variants": ["regular"],
    "subsets": ["mayan-numerals", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1051,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Sans Medefaidrin",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["medefaidrin", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1052,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Meetei Mayek",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["meetei-mayek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1053,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Mende Kikakui",
    "variants": ["regular"],
    "subsets": ["mende-kikakui", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1054,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Meroitic",
    "variants": ["regular"],
    "subsets": [
      "meroitic",
      "meroitic-cursive",
      "meroitic-hieroglyphs",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1055,
    "variable": false,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Sans Miao",
    "variants": ["regular"],
    "subsets": ["miao", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1056,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Sans Modi",
    "variants": ["regular"],
    "subsets": ["modi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1057,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Mongolian",
    "variants": ["regular"],
    "subsets": ["math", "mongolian", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1058,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Noto Sans Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1059,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Mro",
    "variants": ["regular"],
    "subsets": ["mro", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1060,
    "variable": false,
    "lastModified": "2022-12-08"
  },
  {
    "family": "Noto Sans Multani",
    "variants": ["regular"],
    "subsets": ["multani", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1061,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Myanmar",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["myanmar"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1062,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Sans NKo",
    "variants": ["regular"],
    "subsets": ["nko", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1063,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans NKo Unjoined",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["nko", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1064,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Nabataean",
    "variants": ["regular"],
    "subsets": ["nabataean", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1065,
    "variable": false,
    "lastModified": "2023-06-22"
  },
  {
    "family": "Noto Sans Nag Mundari",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["nag-mundari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1066,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Nandinagari",
    "variants": ["regular"],
    "subsets": ["nandinagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1067,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans New Tai Lue",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["new-tai-lue", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1068,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Newa",
    "variants": ["regular"],
    "subsets": ["newa", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1069,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Nushu",
    "variants": ["regular"],
    "subsets": ["nushu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1070,
    "variable": false,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Ogham",
    "variants": ["regular"],
    "subsets": ["ogham", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1071,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Ol Chiki",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["ol-chiki", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1072,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Old Hungarian",
    "variants": ["regular"],
    "subsets": ["old-hungarian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1073,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Old Italic",
    "variants": ["regular"],
    "subsets": ["old-italic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1074,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Old North Arabian",
    "variants": ["regular"],
    "subsets": ["old-north-arabian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1075,
    "variable": false,
    "lastModified": "2023-07-13"
  },
  {
    "family": "Noto Sans Old Permic",
    "variants": ["regular"],
    "subsets": ["cyrillic-ext", "old-permic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1076,
    "variable": false,
    "lastModified": "2023-07-13"
  },
  {
    "family": "Noto Sans Old Persian",
    "variants": ["regular"],
    "subsets": ["old-persian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1077,
    "variable": false,
    "lastModified": "2023-07-13"
  },
  {
    "family": "Noto Sans Old Sogdian",
    "variants": ["regular"],
    "subsets": ["old-sogdian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1078,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Old South Arabian",
    "variants": ["regular"],
    "subsets": ["old-south-arabian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1079,
    "variable": false,
    "lastModified": "2023-07-13"
  },
  {
    "family": "Noto Sans Old Turkic",
    "variants": ["regular"],
    "subsets": ["old-turkic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1080,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Oriya",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["oriya", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1081,
    "variable": true,
    "lastModified": "2024-04-12"
  },
  {
    "family": "Noto Sans Osage",
    "variants": ["regular"],
    "subsets": ["osage", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1082,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Osmanya",
    "variants": ["regular"],
    "subsets": ["osmanya", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1083,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Pahawh Hmong",
    "variants": ["regular"],
    "subsets": ["pahawh-hmong", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1084,
    "variable": false,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Sans Palmyrene",
    "variants": ["regular"],
    "subsets": ["palmyrene", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1085,
    "variable": false,
    "lastModified": "2023-06-22"
  },
  {
    "family": "Noto Sans Pau Cin Hau",
    "variants": ["regular"],
    "subsets": ["pau-cin-hau", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1086,
    "variable": false,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Sans PhagsPa",
    "variants": ["regular"],
    "subsets": ["math", "phags-pa", "symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1087,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Noto Sans Phoenician",
    "variants": ["regular"],
    "subsets": ["phoenician", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1088,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Psalter Pahlavi",
    "variants": ["regular"],
    "subsets": ["psalter-pahlavi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1089,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Rejang",
    "variants": ["regular"],
    "subsets": ["rejang", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1090,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Runic",
    "variants": ["regular"],
    "subsets": ["runic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1091,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans SC",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "chinese-simplified",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["small caps"],
    "lineNumber": 1092,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Samaritan",
    "variants": ["regular"],
    "subsets": ["samaritan", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1093,
    "variable": false,
    "lastModified": "2023-06-22"
  },
  {
    "family": "Noto Sans Saurashtra",
    "variants": ["regular"],
    "subsets": ["saurashtra", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1094,
    "variable": false,
    "lastModified": "2023-09-13"
  },
  {
    "family": "Noto Sans Sharada",
    "variants": ["regular"],
    "subsets": ["sharada", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1095,
    "variable": false,
    "lastModified": "2022-11-18"
  },
  {
    "family": "Noto Sans Shavian",
    "variants": ["regular"],
    "subsets": ["shavian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1096,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Siddham",
    "variants": ["regular"],
    "subsets": ["siddham", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1097,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans SignWriting",
    "variants": ["regular"],
    "subsets": ["signwriting", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["icons"],
    "lineNumber": 1098,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Sans Sinhala",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1099,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Sogdian",
    "variants": ["regular"],
    "subsets": ["sogdian", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1100,
    "variable": false,
    "lastModified": "2023-06-22"
  },
  {
    "family": "Noto Sans Sora Sompeng",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["sora-sompeng", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1101,
    "variable": true,
    "lastModified": "2023-03-09"
  },
  {
    "family": "Noto Sans Soyombo",
    "variants": ["regular"],
    "subsets": ["soyombo", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1102,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Sundanese",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["sundanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1103,
    "variable": true,
    "lastModified": "2024-05-02"
  },
  {
    "family": "Noto Sans Syloti Nagri",
    "variants": ["regular"],
    "subsets": ["syloti-nagri", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1104,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Symbols",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["symbols", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["icons", "dingbat"],
    "lineNumber": 1105,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans Symbols 2",
    "variants": ["regular"],
    "subsets": [
      "braille",
      "math",
      "mayan-numerals",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["icons", "dingbat"],
    "lineNumber": 1106,
    "variable": false,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Noto Sans Syriac",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["syriac", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1107,
    "variable": false,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Syriac Eastern",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["syriac", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1108,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Sans TC",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "chinese-traditional",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1109,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Sans Tagalog",
    "variants": ["regular"],
    "subsets": ["tagalog", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1110,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Tagbanwa",
    "variants": ["regular"],
    "subsets": ["tagbanwa", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1111,
    "variable": false,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Sans Tai Le",
    "variants": ["regular"],
    "subsets": ["tai-le", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1112,
    "variable": false,
    "lastModified": "2022-11-09"
  },
  {
    "family": "Noto Sans Tai Tham",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["tai-tham", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1113,
    "variable": true,
    "lastModified": "2023-09-14"
  },
  {
    "family": "Noto Sans Tai Viet",
    "variants": ["regular"],
    "subsets": ["tai-viet", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1114,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Takri",
    "variants": ["regular"],
    "subsets": ["takri", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1115,
    "variable": false,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Tamil",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1116,
    "variable": true,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Tamil Supplement",
    "variants": ["regular"],
    "subsets": ["tamil-supplement", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1117,
    "variable": false,
    "lastModified": "2023-06-30"
  },
  {
    "family": "Noto Sans Tangsa",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["tangsa", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1118,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Telugu",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1119,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Thaana",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["thaana", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1120,
    "variable": true,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Sans Thai",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["thai", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1121,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Sans Thai Looped",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["thai", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1122,
    "variable": false,
    "lastModified": "2023-04-27"
  },
  {
    "family": "Noto Sans Tifinagh",
    "variants": ["regular"],
    "subsets": ["tifinagh", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1123,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Sans Tirhuta",
    "variants": ["regular"],
    "subsets": ["tirhuta", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1124,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Ugaritic",
    "variants": ["regular"],
    "subsets": ["ugaritic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1125,
    "variable": false,
    "lastModified": "2023-05-23"
  },
  {
    "family": "Noto Sans Vai",
    "variants": ["regular"],
    "subsets": ["vai", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1126,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Sans Vithkuqi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vithkuqi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1127,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Sans Wancho",
    "variants": ["regular"],
    "subsets": ["wancho", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1128,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Sans Warang Citi",
    "variants": ["regular"],
    "subsets": ["warang-citi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1129,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Sans Yi",
    "variants": ["regular"],
    "subsets": ["yi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1130,
    "variable": false,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Sans Zanabazar Square",
    "variants": ["regular"],
    "subsets": ["zanabazar-square", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1131,
    "variable": false,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Serif",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["hinted"],
    "lineNumber": 1132,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Ahom",
    "variants": ["regular"],
    "subsets": ["ahom", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1133,
    "variable": false,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Serif Armenian",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["armenian", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1134,
    "variable": true,
    "lastModified": "2023-11-09"
  },
  {
    "family": "Noto Serif Balinese",
    "variants": ["regular"],
    "subsets": ["balinese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1135,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Serif Bengali",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1136,
    "variable": true,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Noto Serif Devanagari",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1137,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Display",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1138,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Dogra",
    "variants": ["regular"],
    "subsets": ["dogra", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1139,
    "variable": false,
    "lastModified": "2023-11-28"
  },
  {
    "family": "Noto Serif Ethiopic",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["ethiopic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1140,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Georgian",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["georgian", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1141,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Grantha",
    "variants": ["regular"],
    "subsets": ["grantha", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1142,
    "variable": false,
    "lastModified": "2024-06-10"
  },
  {
    "family": "Noto Serif Gujarati",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["gujarati", "math", "symbols", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1143,
    "variable": true,
    "lastModified": "2023-11-28"
  },
  {
    "family": "Noto Serif Gurmukhi",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1144,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif HK",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": [
      "chinese-hongkong",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1145,
    "variable": true,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Noto Serif Hebrew",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["hebrew"],
    "lineNumber": 1146,
    "variable": true,
    "lastModified": "2024-06-10"
  },
  {
    "family": "Noto Serif Hentaigana",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["kana-extended", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1147,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Noto Serif JP",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic", "japanese", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 1148,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Noto Serif KR",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic", "korean", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1149,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Noto Serif Kannada",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1150,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Serif Khitan Small Script",
    "variants": ["regular"],
    "subsets": ["khitan-small-script", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1151,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Serif Khmer",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["khmer", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["khmer"],
    "lineNumber": 1152,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Khojki",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["khojki", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1153,
    "variable": true,
    "lastModified": "2024-02-02"
  },
  {
    "family": "Noto Serif Lao",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["lao", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1154,
    "variable": true,
    "lastModified": "2023-09-27"
  },
  {
    "family": "Noto Serif Makasar",
    "variants": ["regular"],
    "subsets": ["makasar", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1155,
    "variable": false,
    "lastModified": "2023-06-30"
  },
  {
    "family": "Noto Serif Malayalam",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["malayalam", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1156,
    "variable": true,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Serif Myanmar",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["myanmar"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1157,
    "variable": false,
    "lastModified": "2022-09-28"
  },
  {
    "family": "Noto Serif NP Hmong",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["nyiakeng-puachue-hmong", "latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1158,
    "variable": true,
    "lastModified": "2022-12-08"
  },
  {
    "family": "Noto Serif Old Uyghur",
    "variants": ["regular"],
    "subsets": ["old-uyghur", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1159,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Noto Serif Oriya",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["oriya", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1160,
    "variable": true,
    "lastModified": "2023-03-09"
  },
  {
    "family": "Noto Serif Ottoman Siyaq",
    "variants": ["regular"],
    "subsets": ["ottoman-siyaq-numbers", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1161,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Noto Serif SC",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": [
      "chinese-simplified",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 1162,
    "variable": false,
    "lastModified": "2024-07-30"
  },
  {
    "family": "Noto Serif Sinhala",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1163,
    "variable": true,
    "lastModified": "2023-05-02"
  },
  {
    "family": "Noto Serif TC",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": [
      "chinese-traditional",
      "cyrillic",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1164,
    "variable": false,
    "lastModified": "2024-09-23"
  },
  {
    "family": "Noto Serif Tamil",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1165,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Noto Serif Tangut",
    "variants": ["regular"],
    "subsets": ["tangut", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1166,
    "variable": false,
    "lastModified": "2023-05-23"
  },
  {
    "family": "Noto Serif Telugu",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1167,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Serif Thai",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["thai", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1168,
    "variable": true,
    "lastModified": "2023-10-25"
  },
  {
    "family": "Noto Serif Tibetan",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["tibetan", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1169,
    "variable": true,
    "lastModified": "2023-03-09"
  },
  {
    "family": "Noto Serif Todhri",
    "variants": ["regular"],
    "subsets": ["todhri", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1170,
    "variable": false,
    "lastModified": "2025-01-23"
  },
  {
    "family": "Noto Serif Toto",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["toto", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1171,
    "variable": true,
    "lastModified": "2024-07-16"
  },
  {
    "family": "Noto Serif Vithkuqi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["vithkuqi", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1172,
    "variable": false,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Serif Yezidi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["yezidi", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1173,
    "variable": true,
    "lastModified": "2023-07-24"
  },
  {
    "family": "Noto Traditional Nushu",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["nushu", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1174,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Noto Znamenny Musical Notation",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "znamenny", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["color"],
    "lineNumber": 1175,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Nova Cut",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric"],
    "lineNumber": 1176,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Flat",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["techno", "futuristic"],
    "lineNumber": 1177,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Mono",
    "variants": ["regular"],
    "subsets": ["greek", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["round", "code"],
    "lineNumber": 1178,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Oval",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 1179,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Round",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 1180,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted", "script"],
    "lineNumber": 1181,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Slim",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 1182,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Nova Square",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["square"],
    "lineNumber": 1183,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Numans",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 1184,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nunito",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 1185,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nunito Sans",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1186,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Nuosu SIL",
    "variants": ["regular"],
    "subsets": ["yi", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1187,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Odibee Sans",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["futuristic", "square", "techno"],
    "lineNumber": 1188,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Odor Mean Chey",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "serif",
    "tags": ["tall"],
    "lineNumber": 1189,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Offside",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["monolinear", "futuristic", "headlines"],
    "lineNumber": 1190,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oi",
    "variants": ["regular"],
    "subsets": [
      "arabic",
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "tamil",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fat"],
    "lineNumber": 1191,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Ojuju",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["light", "low contrast"],
    "lineNumber": 1192,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Old Standard TT",
    "variants": ["regular", "italic", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1193,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oldenburg",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["german"],
    "lineNumber": 1194,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ole",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["romantic", "swirl"],
    "lineNumber": 1195,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oleo Script",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["script", "headlines"],
    "lineNumber": 1196,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oleo Script Swash Caps",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swash", "script", "headlines"],
    "lineNumber": 1197,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Onest",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "geometric"],
    "lineNumber": 1198,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Oooh Baby",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["playful", "slanted", "handwritten", "light"],
    "lineNumber": 1199,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Open Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1200,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oranienbaum",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["antiqua", "high contrast"],
    "lineNumber": 1201,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Orbit",
    "variants": ["regular"],
    "subsets": ["korean", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["korean", "futuristic"],
    "lineNumber": 1202,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Orbitron",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["geometric", "futuristic", "square", "techno"],
    "lineNumber": 1203,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oregano",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["retro", "cartoon"],
    "lineNumber": 1204,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Orelega One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical"],
    "lineNumber": 1205,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Orienta",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1206,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Original Surfer",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "surf"],
    "lineNumber": 1207,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Oswald",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow"],
    "lineNumber": 1208,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Outfit",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1209,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Over the Rainbow",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 1210,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Overlock",
    "variants": ["regular", "italic", "700", "700italic", "900", "900italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["friendly"],
    "lineNumber": 1211,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Overlock SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["small caps"],
    "lineNumber": 1212,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Overpass",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1213,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Overpass Mono",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1214,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ovo",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["1930s", "large sizes"],
    "lineNumber": 1215,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oxanium",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["square", "futuristic", "game"],
    "lineNumber": 1216,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oxygen",
    "variants": ["300", "regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1217,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Oxygen Mono",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1218,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "PT Mono",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1219,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "PT Sans",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["small sizes"],
    "lineNumber": 1220,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "PT Sans Caption",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1221,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "PT Sans Narrow",
    "variants": ["regular", "700"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1222,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "PT Serif",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 1223,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "PT Serif Caption",
    "variants": ["regular", "italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 1224,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Pacifico",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["1950s", "surf", "brush"],
    "lineNumber": 1225,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Padauk",
    "variants": ["regular", "700"],
    "subsets": ["myanmar", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1226,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Padyakke Expanded One",
    "variants": ["regular"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["wide", "thin", "expanded"],
    "lineNumber": 1227,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Palanquin",
    "variants": ["100", "200", "300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1228,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Palanquin Dark",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 1229,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Palette Mosaic",
    "variants": ["regular"],
    "subsets": ["japanese", "latin"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 1230,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Pangolin",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten", "kids", "friendly"],
    "lineNumber": 1231,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Paprika",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 1232,
    "variable": false,
    "lastModified": "2022-09-22"
  },
  {
    "family": "Parisienne",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["1960s", "cursive", "french"],
    "lineNumber": 1233,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Parkinsans",
    "variants": ["300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "legible", "accessible"],
    "lineNumber": 1234,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Passero One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["block", "geometric", "decorative"],
    "lineNumber": 1235,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Passion One",
    "variants": ["regular", "700", "900"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["block"],
    "lineNumber": 1236,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Passions Conflict",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["elegant", "romantic"],
    "lineNumber": 1237,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pathway Extreme",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 1238,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pathway Gothic One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["gothic"],
    "lineNumber": 1239,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Patrick Hand",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 1240,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Patrick Hand SC",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["small caps"],
    "lineNumber": 1241,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pattaya",
    "variants": ["regular"],
    "subsets": ["cyrillic", "thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["fun"],
    "lineNumber": 1242,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Patua One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["low contrast"],
    "lineNumber": 1243,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pavanam",
    "variants": ["regular"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["light"],
    "lineNumber": 1244,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Paytone One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 1245,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Peddana",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "serif",
    "tags": ["condensed"],
    "lineNumber": 1246,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Peralta",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["retro", "comic"],
    "lineNumber": 1247,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Permanent Marker",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["caps", "handwritten"],
    "lineNumber": 1248,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Petemoss",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 1249,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Petit Formal Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 1250,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Petrona",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["cursive", "low contrast"],
    "lineNumber": 1251,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Phetsarath",
    "variants": ["regular", "700"],
    "subsets": ["lao"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1252,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Philosopher",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["hinted"],
    "lineNumber": 1253,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Phudu",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["caps"],
    "lineNumber": 1254,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Piazzolla",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1255,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Piedra",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cartoon"],
    "lineNumber": 1256,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pinyon Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["romantic", "cursive", "script"],
    "lineNumber": 1257,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pirata One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["gothic", "calligraphic"],
    "lineNumber": 1258,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pixelify Sans",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["pixel"],
    "lineNumber": 1259,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Plaster",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil", "headlines", "low contrast", "geometric"],
    "lineNumber": 1260,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Platypi",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1261,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Play",
    "variants": ["regular", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["game", "square"],
    "lineNumber": 1262,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Playball",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["cursive"],
    "lineNumber": 1263,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Playfair",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1264,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Playfair Display",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["didone", "high contrast", "transitional", "headlines"],
    "lineNumber": 1265,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Playfair Display SC",
    "variants": ["regular", "italic", "700", "700italic", "900", "900italic"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps", "didone", "high contrast", "transitional"],
    "lineNumber": 1266,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Playpen Sans",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["emoji", "math", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["kids", "playful", "handwritten"],
    "lineNumber": 1267,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Playwrite AR",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected"],
    "lineNumber": 1268,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AR Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1269,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AT",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "100italic",
      "200italic",
      "300italic",
      "italic"
    ],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected"],
    "lineNumber": 1270,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AT Guides",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1271,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite AU NSW",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1272,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AU NSW Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1273,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite AU QLD",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1274,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AU QLD Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1275,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite AU SA",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1276,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AU SA Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1277,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite AU TAS",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1278,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AU TAS Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1279,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite AU VIC",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1280,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite AU VIC Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1281,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite BE VLG",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1282,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite BE VLG Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1283,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite BE WAL",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1284,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Playwrite BE WAL Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1285,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite BR",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected"],
    "lineNumber": 1286,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite BR Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1287,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite CA",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1288,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite CA Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1289,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite CL",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1290,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite CL Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1291,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite CO",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1292,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite CO Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1293,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite CU",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1294,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite CU Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1295,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite CZ",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1296,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite CZ Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1297,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DE Grund",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["literacy"],
    "lineNumber": 1298,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DE Grund Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1299,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DE LA",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1300,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DE LA Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1301,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DE SAS",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1302,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DE SAS Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1303,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DE VA",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected", "slanted"],
    "lineNumber": 1304,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DE VA Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1305,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DK Loopet",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1306,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DK Loopet Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1307,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite DK Uloopet",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1308,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite DK Uloopet Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1309,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite ES",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected"],
    "lineNumber": 1310,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite ES Deco",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "connected"],
    "lineNumber": 1311,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite ES Deco Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1312,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite ES Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1313,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite FR Moderne",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1314,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite FR Moderne Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1315,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite FR Trad",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1316,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite FR Trad Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1317,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite GB J",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "100italic",
      "200italic",
      "300italic",
      "italic"
    ],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1318,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite GB J Guides",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1319,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite GB S",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "100italic",
      "200italic",
      "300italic",
      "italic"
    ],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1320,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite GB S Guides",
    "variants": ["regular", "italic"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1321,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite HR",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1322,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite HR Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1323,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite HR Lijeva",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1324,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite HR Lijeva Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1325,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite HU",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1326,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite HU Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1327,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite ID",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1328,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite ID Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1329,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite IE",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1330,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite IE Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1331,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite IN",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1332,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite IN Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1333,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite IS",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1334,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite IS Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1335,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite IT Moderna",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1336,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Playwrite IT Moderna Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1337,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite IT Trad",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1338,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite IT Trad Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1339,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite MX",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1340,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite MX Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1341,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite NG Modern",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1342,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite NG Modern Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1343,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite NL",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1344,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite NL Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1345,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite NO",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1346,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite NO Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1347,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite NZ",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1348,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite NZ Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1349,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite PE",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1350,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite PE Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1351,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite PL",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1352,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite PL Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1353,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite PT",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1354,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite PT Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1355,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite RO",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1356,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite RO Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1357,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite SK",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1358,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite SK Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1359,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite TZ",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1360,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite TZ Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1361,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite US Modern",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1362,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite US Modern Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1363,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite US Trad",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1364,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite US Trad Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1365,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite VN",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1366,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite VN Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1367,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Playwrite ZA",
    "variants": ["100", "200", "300", "regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1368,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Playwrite ZA Guides",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": [],
    "lineNumber": 1369,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Plus Jakarta Sans",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1370,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pochaevsk",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin"],
    "category": "display",
    "tags": [],
    "lineNumber": 1371,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Podkova",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["monolinear", "slab", "small sizes"],
    "lineNumber": 1372,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Poetsen One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round", "friendly"],
    "lineNumber": 1373,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Poiret One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["art deco", "gothic", "geometric"],
    "lineNumber": 1374,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Poller One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["heavy", "high contrast"],
    "lineNumber": 1375,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Poltawski Nowy",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1376,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Poly",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small sizes"],
    "lineNumber": 1377,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Pompiere",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["tall", "large sizes", "low contrast"],
    "lineNumber": 1378,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ponnala",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "display",
    "tags": [],
    "lineNumber": 1379,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Ponomar",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin"],
    "category": "display",
    "tags": [],
    "lineNumber": 1380,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Pontano Sans",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["thin"],
    "lineNumber": 1381,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Poor Story",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["korean"],
    "lineNumber": 1382,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Poppins",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "monolinear"],
    "lineNumber": 1383,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Port Lligat Sans",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["round", "friendly", "groovy"],
    "lineNumber": 1384,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Port Lligat Slab",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab", "friendly", "groovy"],
    "lineNumber": 1385,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Potta One",
    "variants": ["regular"],
    "subsets": ["japanese", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 1386,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Pragati Narrow",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1387,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Praise",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script", "slanted"],
    "lineNumber": 1388,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Prata",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin"],
    "category": "serif",
    "tags": ["didone", "high contrast"],
    "lineNumber": 1389,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Preahvihear",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "sans-serif",
    "tags": ["tall"],
    "lineNumber": 1390,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Press Start 2P",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "display",
    "tags": ["game", "1980s", "pixel"],
    "lineNumber": 1391,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Pridi",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1392,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Princess Sofia",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cute", "calligraphic"],
    "lineNumber": 1393,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Prociono",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["blackletter"],
    "lineNumber": 1394,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Prompt",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["loopless", "wide", "legibility"],
    "lineNumber": 1395,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Prosto One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["grotesque"],
    "lineNumber": 1396,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Protest Guerrilla",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage", "stencil"],
    "lineNumber": 1397,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Protest Revolution",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage", "brush"],
    "lineNumber": 1398,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Protest Riot",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage", "marker"],
    "lineNumber": 1399,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Protest Strike",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage"],
    "lineNumber": 1400,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Proza Libre",
    "variants": [
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1401,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Public Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1402,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Puppies Play",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["curls", "kids", "playful", "fun"],
    "lineNumber": 1403,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Puritan",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 1404,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Purple Purse",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1950s", "vintage"],
    "lineNumber": 1405,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Qahiri",
    "variants": ["regular"],
    "subsets": ["arabic", "latin"],
    "category": "sans-serif",
    "tags": ["arabic"],
    "lineNumber": 1406,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Quando",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["brush"],
    "lineNumber": 1407,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Quantico",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 1408,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Quattrocento",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["low contrast"],
    "lineNumber": 1409,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Quattrocento Sans",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["small sizes"],
    "lineNumber": 1410,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Questrial",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1411,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Quicksand",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["light"],
    "lineNumber": 1412,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Quintessential",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush", "calligraphic"],
    "lineNumber": 1413,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Qwigley",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "brush"],
    "lineNumber": 1414,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Qwitcher Grypen",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 1415,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "REM",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 1416,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Racing Sans One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["speed", "slanted"],
    "lineNumber": 1417,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Radio Canada",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["canadian-aboriginal", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility", "humanist"],
    "lineNumber": 1418,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Radio Canada Big",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["friendly", "humanist"],
    "lineNumber": 1419,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Radley",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["engraved"],
    "lineNumber": 1420,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rajdhani",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["headlines"],
    "lineNumber": 1421,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Rakkas",
    "variants": ["regular"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["blackletter"],
    "lineNumber": 1422,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Raleway",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["elegant", "geometric"],
    "lineNumber": 1423,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Raleway Dots",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["dotted"],
    "lineNumber": 1424,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ramabhadra",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["large sizes", "headlines"],
    "lineNumber": 1425,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Ramaraja",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1426,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Rambla",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1427,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rammetto One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["vintage"],
    "lineNumber": 1428,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rampart One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["comic", "shadow"],
    "lineNumber": 1429,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Ranchers",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1950s", "disco", "fun", "headlines"],
    "lineNumber": 1430,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rancho",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["playful"],
    "lineNumber": 1431,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ranga",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted", "brush"],
    "lineNumber": 1432,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rasa",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["gujarati", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1433,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rationale",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["geometric", "monolinear"],
    "lineNumber": 1434,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ravi Prakash",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "display",
    "tags": ["tapered"],
    "lineNumber": 1435,
    "variable": false,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Readex Pro",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1436,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Recursive",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["code"],
    "lineNumber": 1437,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Red Hat Display",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1438,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Red Hat Mono",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["geometric", "code"],
    "lineNumber": 1439,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Red Hat Text",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1440,
    "variable": true,
    "lastModified": "2024-12-04"
  },
  {
    "family": "Red Rose",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["signage"],
    "lineNumber": 1441,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Redacted",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["wireframe"],
    "lineNumber": 1442,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Redacted Script",
    "variants": ["300", "regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["wireframe"],
    "lineNumber": 1443,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Reddit Mono",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["humanist"],
    "lineNumber": 1444,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Reddit Sans",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1445,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Reddit Sans Condensed",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1446,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Redressed",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 1447,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Reem Kufi",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["1900s"],
    "lineNumber": 1448,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Reem Kufi Fun",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["color", "fun", "hearts"],
    "lineNumber": 1449,
    "variable": true,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Reem Kufi Ink",
    "variants": ["regular"],
    "subsets": ["arabic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["arabic", "color"],
    "lineNumber": 1450,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Reenie Beanie",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["scrawl", "handwritten"],
    "lineNumber": 1451,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Reggae One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["japanese"],
    "lineNumber": 1452,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rethink Sans",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1453,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Revalia",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["wide"],
    "lineNumber": 1454,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rhodium Libre",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["wide"],
    "lineNumber": 1455,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Ribeye",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["tattoo", "cartoon"],
    "lineNumber": 1456,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ribeye Marrow",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["tattoo", "outline", "cartoon"],
    "lineNumber": 1457,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Righteous",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["art deco", "disco", "geometric"],
    "lineNumber": 1458,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Risque",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1960s", "retro", "cartoon"],
    "lineNumber": 1459,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Road Rage",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 1460,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Roboto",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 1461,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Roboto Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1462,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Roboto Flex",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1463,
    "variable": true,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Roboto Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1464,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Roboto Serif",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1465,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Roboto Slab",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["slab", "geometric"],
    "lineNumber": 1466,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rochester",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive", "art deco"],
    "lineNumber": 1467,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rock 3D",
    "variants": ["regular"],
    "subsets": ["japanese", "latin"],
    "category": "display",
    "tags": ["outline"],
    "lineNumber": 1468,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Rock Salt",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["caps", "marker"],
    "lineNumber": 1469,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "RocknRoll One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["rock", "groovy"],
    "lineNumber": 1470,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rokkitt",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "egyptian", "headlines"],
    "lineNumber": 1471,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Romanesco",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 1472,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ropa Sans",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1473,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rosario",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1474,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rosarivo",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["whimsical"],
    "lineNumber": 1475,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rouge Script",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["copperplate", "script"],
    "lineNumber": 1476,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rowdies",
    "variants": ["300", "regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["block", "groovy"],
    "lineNumber": 1477,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rozha One",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["heavy", "didone", "fat face", "high contrast"],
    "lineNumber": 1478,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "arabic",
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 1479,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik 80s Fade",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["1980s"],
    "lineNumber": 1480,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Beastly",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["grunge"],
    "lineNumber": 1481,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Broken Fax",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fun", "fat", "uneven"],
    "lineNumber": 1482,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Bubbles",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["bubbly"],
    "lineNumber": 1483,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Burned",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline", "grunge"],
    "lineNumber": 1484,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Dirt",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["distressed"],
    "lineNumber": 1485,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Distressed",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["distressed"],
    "lineNumber": 1486,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Doodle Shadow",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["shadow", "outline", "fat", "sketch", "comic"],
    "lineNumber": 1487,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Doodle Triangles",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fun", "fat", "sketch"],
    "lineNumber": 1488,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Gemstones",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fat", "fun"],
    "lineNumber": 1489,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Glitch",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["glitch"],
    "lineNumber": 1490,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Glitch Pop",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fat", "glitch", "distressed"],
    "lineNumber": 1491,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Iso",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline", "grunge"],
    "lineNumber": 1492,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Lines",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fat", "lines", "uneven"],
    "lineNumber": 1493,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Maps",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fun", "fat"],
    "lineNumber": 1494,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Marker Hatch",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["glitch", "sketch"],
    "lineNumber": 1495,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Maze",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fat"],
    "lineNumber": 1496,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Microbe",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fat"],
    "lineNumber": 1497,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Mono One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["caps", "heavy", "round"],
    "lineNumber": 1498,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Rubik Moonrocks",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["fat", "fun"],
    "lineNumber": 1499,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Pixels",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["pixel"],
    "lineNumber": 1500,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Puddles",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline", "bubbly"],
    "lineNumber": 1501,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Scribble",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "hebrew",
      "math",
      "symbols",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["fat", "outline", "uneven"],
    "lineNumber": 1502,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Spray Paint",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["uneven", "graffiti"],
    "lineNumber": 1503,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Storm",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["distressed"],
    "lineNumber": 1504,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Vinyl",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline"],
    "lineNumber": 1505,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rubik Wet Paint",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "hebrew", "latin", "latin-ext"],
    "category": "display",
    "tags": ["halloween", "graffiti"],
    "lineNumber": 1506,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruda",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 1507,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rufina",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["didone", "high contrast"],
    "lineNumber": 1508,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruge Boogie",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["fun", "swirl"],
    "lineNumber": 1509,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruluko",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["literacy"],
    "lineNumber": 1510,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rum Raisin",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["disco", "vintage", "1960s"],
    "lineNumber": 1511,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruslan Display",
    "variants": ["regular"],
    "subsets": ["cyrillic", "math", "symbols", "latin", "latin-ext"],
    "category": "display",
    "tags": ["wide", "decorative", "heavy"],
    "lineNumber": 1512,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Russo One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy", "headlines"],
    "lineNumber": 1513,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruthie",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "decorative"],
    "lineNumber": 1514,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ruwudu",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1515,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Rye",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["western", "large sizes", "headlines"],
    "lineNumber": 1516,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "STIX Two Text",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1517,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "SUSE",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1518,
    "variable": false,
    "lastModified": "2024-08-28"
  },
  {
    "family": "Sacramento",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1950s", "1960s", "connected", "headlines"],
    "lineNumber": 1519,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sahitya",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1520,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Sail",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swash", "didone"],
    "lineNumber": 1521,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Saira",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "techno"],
    "lineNumber": 1522,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Saira Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1523,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Saira Extra Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1524,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Saira Semi Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1525,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Saira Stencil One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["stencil"],
    "lineNumber": 1526,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Salsa",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["1970s"],
    "lineNumber": 1527,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sanchez",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1528,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sancreek",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["western"],
    "lineNumber": 1529,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sankofa Display",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square", "geometric"],
    "lineNumber": 1530,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sansita",
    "variants": [
      "regular",
      "italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1531,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sansita Swashed",
    "variants": ["300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["swash"],
    "lineNumber": 1532,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sarabun",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 1533,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sarala",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast"],
    "lineNumber": 1534,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sarina",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cursive", "brush"],
    "lineNumber": 1535,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sarpanch",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square", "techno", "high contrast", "headlines"],
    "lineNumber": 1536,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sassy Frass",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["fun"],
    "lineNumber": 1537,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Satisfy",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 1538,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sawarabi Gothic",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 1539,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sawarabi Mincho",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 1540,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Scada",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1541,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Scheherazade New",
    "variants": ["regular", "500", "600", "700"],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["arabic"],
    "lineNumber": 1542,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Schibsted Grotesk",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1543,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Schoolbell",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["kids"],
    "lineNumber": 1544,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Scope One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab", "thin"],
    "lineNumber": 1545,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Seaweed Script",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cursive", "script"],
    "lineNumber": 1546,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Secular One",
    "variants": ["regular"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1547,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sedan",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1548,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sedan SC",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 1549,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sedgwick Ave",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["graffiti", "handwritten"],
    "lineNumber": 1550,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sedgwick Ave Display",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["caps", "graffiti", "handwritten"],
    "lineNumber": 1551,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sen",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1552,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Send Flowers",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "playful", "cute"],
    "lineNumber": 1553,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sevillana",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["swirl", "large sizes", "cute"],
    "lineNumber": 1554,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Seymour One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy", "wide"],
    "lineNumber": 1555,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shadows Into Light",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["comic", "handwritten"],
    "lineNumber": 1556,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Shadows Into Light Two",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["comic", "handwritten"],
    "lineNumber": 1557,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shafarik",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "glagolitic", "latin", "latin-ext"],
    "category": "display",
    "tags": [],
    "lineNumber": 1558,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Shalimar",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script", "condensed"],
    "lineNumber": 1559,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shantell Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["handwritten", "marker"],
    "lineNumber": 1560,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shanti",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1561,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Share",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 1562,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Share Tech",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["techno"],
    "lineNumber": 1563,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Share Tech Mono",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "monospace",
    "tags": ["techno", "code"],
    "lineNumber": 1564,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shippori Antique",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["manga"],
    "lineNumber": 1565,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shippori Antique B1",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 1566,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shippori Mincho",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 1567,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shippori Mincho B1",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese", "light"],
    "lineNumber": 1568,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shizuru",
    "variants": ["regular"],
    "subsets": ["japanese", "latin"],
    "category": "display",
    "tags": ["outline", "handwritten"],
    "lineNumber": 1569,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Shojumaru",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["1950s"],
    "lineNumber": 1570,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Short Stack",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cartoon", "kids", "low contrast", "geometric"],
    "lineNumber": 1571,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Shrikhand",
    "variants": ["regular"],
    "subsets": ["gujarati", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 1572,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Siemreap",
    "variants": ["regular"],
    "subsets": ["khmer"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1573,
    "variable": false,
    "lastModified": "2025-01-08"
  },
  {
    "family": "Sigmar",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "pulp"],
    "lineNumber": 1574,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sigmar One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "caps", "pulp"],
    "lineNumber": 1575,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Signika",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 1576,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Signika Negative",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 1577,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Silkscreen",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["retro", "techno", "game", "pixel"],
    "lineNumber": 1578,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Simonetta",
    "variants": ["regular", "italic", "900", "900italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 1579,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Single Day",
    "variants": ["regular"],
    "subsets": ["korean"],
    "category": "display",
    "tags": ["korean"],
    "lineNumber": 1580,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Sintony",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1581,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sirin Stencil",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["stencil", "brush", "large sizes"],
    "lineNumber": 1582,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Six Caps",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1583,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Sixtyfour",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["pixel", "retro"],
    "lineNumber": 1584,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sixtyfour Convergence",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["color", "pixel", "game", "gradient", "retro"],
    "lineNumber": 1585,
    "variable": false,
    "lastModified": "2025-03-03"
  },
  {
    "family": "Skranji",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["funky"],
    "lineNumber": 1586,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Slabo 13px",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1587,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Slabo 27px",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1588,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Slackey",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["retro"],
    "lineNumber": 1589,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Slackside One",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["japanese"],
    "lineNumber": 1590,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Smokum",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["western", "headlines"],
    "lineNumber": 1591,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Smooch",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten", "brush"],
    "lineNumber": 1592,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Smooch Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square", "techno"],
    "lineNumber": 1593,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Smythe",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["retro", "art deco"],
    "lineNumber": 1594,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sniglet",
    "variants": ["regular", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["round", "headlines"],
    "lineNumber": 1595,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Snippet",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["whimsical", "headlines"],
    "lineNumber": 1596,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Snowburst One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["fun", "kids", "whimsical"],
    "lineNumber": 1597,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofadi One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun"],
    "lineNumber": 1598,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofia",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["friendly"],
    "lineNumber": 1599,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofia Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1600,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofia Sans Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1601,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofia Sans Extra Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1602,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sofia Sans Semi Condensed",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1603,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Solitreo",
    "variants": ["regular"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1604,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Solway",
    "variants": ["300", "regular", "500", "700", "800"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1605,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sometype Mono",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1606,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Song Myung",
    "variants": ["regular"],
    "subsets": ["korean"],
    "category": "serif",
    "tags": ["korean"],
    "lineNumber": 1607,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Sono",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1608,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sonsie One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["wide"],
    "lineNumber": 1609,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sora",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "friendly"],
    "lineNumber": 1610,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sorts Mill Goudy",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 1611,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sour Gummy",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "fun", "playful", "kids"],
    "lineNumber": 1612,
    "variable": false,
    "lastModified": "2024-11-07"
  },
  {
    "family": "Source Code Pro",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["typewriter", "code"],
    "lineNumber": 1613,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Source Sans 3",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["legibility"],
    "lineNumber": 1614,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Source Serif 4",
    "variants": [
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["transitional"],
    "lineNumber": 1615,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Space Grotesk",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1616,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Space Mono",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["geometric"],
    "lineNumber": 1617,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Special Elite",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["typewriter", "grunge"],
    "lineNumber": 1618,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Spectral",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["elegant"],
    "lineNumber": 1619,
    "variable": false,
    "lastModified": "2024-11-05"
  },
  {
    "family": "Spectral SC",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 1620,
    "variable": false,
    "lastModified": "2024-11-05"
  },
  {
    "family": "Spicy Rice",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["disco", "retro"],
    "lineNumber": 1621,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Spinnaker",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["fun"],
    "lineNumber": 1622,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Spirax",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["whimsical", "headlines"],
    "lineNumber": 1623,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Splash",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["fuzzy"],
    "lineNumber": 1624,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Spline Sans",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 1625,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Spline Sans Mono",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": [],
    "lineNumber": 1626,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Squada One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["geometric"],
    "lineNumber": 1627,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Square Peg",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["handwritten"],
    "lineNumber": 1628,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sree Krushnadevaraya",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "serif",
    "tags": ["headlines"],
    "lineNumber": 1629,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Sriracha",
    "variants": ["regular"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["slanted"],
    "lineNumber": 1630,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Srisakdi",
    "variants": ["regular", "700"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 1631,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Staatliches",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["caps", "block", "condensed"],
    "lineNumber": 1632,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stalemate",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["vintage", "cursive"],
    "lineNumber": 1633,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stalinist One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic", "wide"],
    "lineNumber": 1634,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stardos Stencil",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["stencil"],
    "lineNumber": 1635,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stick",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["square"],
    "lineNumber": 1636,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stick No Bills",
    "variants": ["200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["stencil"],
    "lineNumber": 1637,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stint Ultra Condensed",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["condensed"],
    "lineNumber": 1638,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stint Ultra Expanded",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["wide", "expanded"],
    "lineNumber": 1639,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stoke",
    "variants": ["300", "regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 1640,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Strait",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1641,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Style Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 1642,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Stylish",
    "variants": ["regular"],
    "subsets": ["korean"],
    "category": "sans-serif",
    "tags": ["korean", "uneven"],
    "lineNumber": 1643,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Sue Ellen Francisco",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["tall", "condensed"],
    "lineNumber": 1644,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Suez One",
    "variants": ["regular"],
    "subsets": ["hebrew", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["heavy"],
    "lineNumber": 1645,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sulphur Point",
    "variants": ["300", "regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1646,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sumana",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1647,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Sunflower",
    "variants": ["300", "500", "700"],
    "subsets": ["korean", "latin"],
    "category": "sans-serif",
    "tags": ["geometric", "korean"],
    "lineNumber": 1648,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Sunshiney",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["fun", "swirl"],
    "lineNumber": 1649,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Supermercado One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric", "swash", "low contrast"],
    "lineNumber": 1650,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Sura",
    "variants": ["regular", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1651,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Suranna",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "serif",
    "tags": ["high contrast"],
    "lineNumber": 1652,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Suravaram",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "serif",
    "tags": ["brush", "script", "handwritten"],
    "lineNumber": 1653,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Suwannaphum",
    "variants": ["100", "300", "regular", "700", "900"],
    "subsets": ["khmer", "latin"],
    "category": "serif",
    "tags": ["modern"],
    "lineNumber": 1654,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Swanky and Moo Moo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 1655,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Syncopate",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["futuristic", "unicase"],
    "lineNumber": 1656,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Syne",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1657,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Syne Mono",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "monospace",
    "tags": ["uneven", "glitch"],
    "lineNumber": 1658,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Syne Tactile",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted", "marker", "handwritten"],
    "lineNumber": 1659,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tac One",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["marker"],
    "lineNumber": 1660,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Tai Heritage Pro",
    "variants": ["regular", "700"],
    "subsets": ["tai-viet", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1661,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Tajawal",
    "variants": ["200", "300", "regular", "500", "700", "800", "900"],
    "subsets": ["arabic", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1662,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tangerine",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 1663,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tapestry",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic", "roman"],
    "lineNumber": 1664,
    "variable": false,
    "lastModified": "2023-08-25"
  },
  {
    "family": "Taprom",
    "variants": ["regular"],
    "subsets": ["khmer", "latin"],
    "category": "display",
    "tags": ["round", "slanted"],
    "lineNumber": 1665,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Tauri",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1666,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Taviraj",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["looped", "high contrast"],
    "lineNumber": 1667,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Teachers",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": ["greek-ext", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["legibility", "literacy"],
    "lineNumber": 1668,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Teko",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["headlines"],
    "lineNumber": 1669,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tektur",
    "variants": ["regular", "500", "600", "700", "800", "900"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "display",
    "tags": ["techno", "futuristic"],
    "lineNumber": 1670,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Telex",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "low contrast"],
    "lineNumber": 1671,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tenali Ramakrishna",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["small sizes"],
    "lineNumber": 1672,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Tenor Sans",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist"],
    "lineNumber": 1673,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Text Me One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["monolinear"],
    "lineNumber": 1674,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Texturina",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["blackletter"],
    "lineNumber": 1675,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Thasadith",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["humanist", "round", "light"],
    "lineNumber": 1676,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "The Girl Next Door",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl", "kids"],
    "lineNumber": 1677,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "The Nautigal",
    "variants": ["regular", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 1678,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tienne",
    "variants": ["regular", "700", "900"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["friendly", "legibility"],
    "lineNumber": 1679,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tillana",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["slanted"],
    "lineNumber": 1680,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tilt Neon",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric", "round"],
    "lineNumber": 1681,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Tilt Prism",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric", "outline"],
    "lineNumber": 1682,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Tilt Warp",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric"],
    "lineNumber": 1683,
    "variable": true,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Timmana",
    "variants": ["regular"],
    "subsets": ["telugu", "latin"],
    "category": "sans-serif",
    "tags": ["slanted"],
    "lineNumber": 1684,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Tinos",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "hebrew",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": [],
    "lineNumber": 1685,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiny5",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "greek", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["game", "pixel", "retro"],
    "lineNumber": 1686,
    "variable": false,
    "lastModified": "2024-12-10"
  },
  {
    "family": "Tiro Bangla",
    "variants": ["regular", "italic"],
    "subsets": ["bengali", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1687,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Devanagari Hindi",
    "variants": ["regular", "italic"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1688,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Devanagari Marathi",
    "variants": ["regular", "italic"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1689,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Devanagari Sanskrit",
    "variants": ["regular", "italic"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1690,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Gurmukhi",
    "variants": ["regular", "italic"],
    "subsets": ["gurmukhi", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1691,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Kannada",
    "variants": ["regular", "italic"],
    "subsets": ["kannada", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1692,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Tamil",
    "variants": ["regular", "italic"],
    "subsets": ["tamil", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1693,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tiro Telugu",
    "variants": ["regular", "italic"],
    "subsets": ["telugu", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1694,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Titan One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["brush", "fat"],
    "lineNumber": 1695,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Titillium Web",
    "variants": [
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "900"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1696,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tomorrow",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric"],
    "lineNumber": 1697,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tourney",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["outline", "collegiate"],
    "lineNumber": 1698,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Trade Winds",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["caps", "grunge"],
    "lineNumber": 1699,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Train One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["lines", "outline"],
    "lineNumber": 1700,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Triodion",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "latin"],
    "category": "display",
    "tags": [],
    "lineNumber": 1701,
    "variable": false,
    "lastModified": "2025-02-12"
  },
  {
    "family": "Trirong",
    "variants": [
      "100",
      "100italic",
      "200",
      "200italic",
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic",
      "900",
      "900italic"
    ],
    "subsets": ["thai", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["looped", "high contrast", "legibility"],
    "lineNumber": 1702,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Trispace",
    "variants": ["100", "200", "300", "regular", "500", "600", "700", "800"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["wide"],
    "lineNumber": 1703,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Trocchi",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1704,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Trochut",
    "variants": ["regular", "italic", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["fun", "geometric"],
    "lineNumber": 1705,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Truculenta",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow", "condensed"],
    "lineNumber": 1706,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Trykker",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["old style", "high contrast"],
    "lineNumber": 1707,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Tsukimi Rounded",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "japanese"],
    "lineNumber": 1708,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Tulpen One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["tall", "condensed"],
    "lineNumber": 1709,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Turret Road",
    "variants": ["200", "300", "regular", "500", "700", "800"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["low contrast", "geometric", "futuristic"],
    "lineNumber": 1710,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Twinkle Star",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cute", "kids", "playful", "swirl"],
    "lineNumber": 1711,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ubuntu",
    "variants": [
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "700",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1712,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ubuntu Condensed",
    "variants": ["regular"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["condensed"],
    "lineNumber": 1713,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ubuntu Mono",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1714,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ubuntu Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1715,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Ubuntu Sans Mono",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "greek-ext",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": [],
    "lineNumber": 1716,
    "variable": false,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Uchen",
    "variants": ["regular"],
    "subsets": ["tibetan", "latin"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1717,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ultra",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["heavy", "headlines"],
    "lineNumber": 1718,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Unbounded",
    "variants": ["200", "300", "regular", "500", "600", "700", "800", "900"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "round"],
    "lineNumber": 1719,
    "variable": true,
    "lastModified": "2024-09-30"
  },
  {
    "family": "Uncial Antiqua",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["ancient", "antiqua"],
    "lineNumber": 1720,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Underdog",
    "variants": ["regular"],
    "subsets": ["cyrillic", "latin", "latin-ext"],
    "category": "display",
    "tags": ["cartoon", "kids", "headlines"],
    "lineNumber": 1721,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Unica One",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic", "headlines", "unicase"],
    "lineNumber": 1722,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "UnifrakturCook",
    "variants": ["700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["german", "fraktur", "blackletter"],
    "lineNumber": 1723,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "UnifrakturMaguntia",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["german", "fraktur", "blackletter"],
    "lineNumber": 1724,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Unkempt",
    "variants": ["regular", "700"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["kids", "fun", "cartoon"],
    "lineNumber": 1725,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Unlock",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["geometric", "headlines"],
    "lineNumber": 1726,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Unna",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["neoclassical"],
    "lineNumber": 1727,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Updock",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["formal", "script"],
    "lineNumber": 1728,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Urbanist",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["low contrast", "modern", "geometric"],
    "lineNumber": 1729,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "VT323",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["code", "pixel", "game"],
    "lineNumber": 1730,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vampiro One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["cursive", "monolinear", "low contrast"],
    "lineNumber": 1731,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Varela",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1732,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Varela Round",
    "variants": ["regular"],
    "subsets": ["hebrew", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round"],
    "lineNumber": 1733,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Varta",
    "variants": ["300", "regular", "500", "600", "700"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1734,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vast Shadow",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["shadow"],
    "lineNumber": 1735,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vazirmatn",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["arabic", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1736,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vesper Libre",
    "variants": ["regular", "500", "700", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1737,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Viaoda Libre",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["whimsical"],
    "lineNumber": 1738,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vibes",
    "variants": ["regular"],
    "subsets": ["arabic", "latin"],
    "category": "display",
    "tags": ["round"],
    "lineNumber": 1739,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vibur",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 1740,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Victor Mono",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "monospace",
    "tags": ["code"],
    "lineNumber": 1741,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vidaloka",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["didone", "high contrast", "headlines"],
    "lineNumber": 1742,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Viga",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 1743,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vina Sans",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["heavy", "signage"],
    "lineNumber": 1744,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Voces",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["modern"],
    "lineNumber": 1745,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Volkhov",
    "variants": ["regular", "italic", "700", "700italic"],
    "subsets": ["latin"],
    "category": "serif",
    "tags": ["low contrast"],
    "lineNumber": 1746,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vollkorn",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "serif",
    "tags": ["old style"],
    "lineNumber": 1747,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Vollkorn SC",
    "variants": ["regular", "600", "700", "900"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["small caps"],
    "lineNumber": 1748,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Voltaire",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["geometric", "condensed", "low contrast"],
    "lineNumber": 1749,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Vujahday Script",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["script"],
    "lineNumber": 1750,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Waiting for the Sunrise",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 1751,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Wallpoet",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["stencil"],
    "lineNumber": 1752,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Walter Turncoat",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "handwriting",
    "tags": ["caps"],
    "lineNumber": 1753,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Warnes",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["vintage", "connected"],
    "lineNumber": 1754,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Water Brush",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["brush"],
    "lineNumber": 1755,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Waterfall",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["elegant", "cursive", "calligraphic"],
    "lineNumber": 1756,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Wavefont",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": ["latin"],
    "category": "display",
    "tags": ["diagram"],
    "lineNumber": 1757,
    "variable": false,
    "lastModified": "2024-11-14"
  },
  {
    "family": "Wellfleet",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["fun", "slab", "low contrast"],
    "lineNumber": 1758,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Wendy One",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["heavy"],
    "lineNumber": 1759,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Whisper",
    "variants": ["regular"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 1760,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "WindSong",
    "variants": ["regular", "500"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive"],
    "lineNumber": 1761,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Winky Sans",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "friendly"],
    "lineNumber": 1762,
    "variable": false,
    "lastModified": "2025-03-18"
  },
  {
    "family": "Wire One",
    "variants": ["regular"],
    "subsets": ["latin"],
    "category": "sans-serif",
    "tags": ["thin", "art nouveau", "condensed"],
    "lineNumber": 1763,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Wittgenstein",
    "variants": [
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1764,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Wix Madefor Display",
    "variants": ["regular", "500", "600", "700", "800"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["compact"],
    "lineNumber": 1765,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Wix Madefor Text",
    "variants": [
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic",
      "800",
      "800italic"
    ],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["compact"],
    "lineNumber": 1766,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Work Sans",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["grotesque"],
    "lineNumber": 1767,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Workbench",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin"],
    "category": "monospace",
    "tags": ["pixel", "retro"],
    "lineNumber": 1768,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Xanh Mono",
    "variants": ["regular", "italic"],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "monospace",
    "tags": ["compact"],
    "lineNumber": 1769,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yaldevi",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": ["sinhala", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["narrow"],
    "lineNumber": 1770,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yanone Kaffeesatz",
    "variants": ["200", "300", "regular", "500", "600", "700"],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["1920s"],
    "lineNumber": 1771,
    "variable": true,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yantramanav",
    "variants": ["100", "300", "regular", "500", "700", "900"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1772,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yarndings 12",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin"],
    "category": "display",
    "tags": ["dingbat", "knit"],
    "lineNumber": 1773,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Yarndings 12 Charted",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin"],
    "category": "display",
    "tags": ["dingbat", "knit"],
    "lineNumber": 1774,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Yarndings 20",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin"],
    "category": "display",
    "tags": ["dingbat", "knit"],
    "lineNumber": 1775,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Yarndings 20 Charted",
    "variants": ["regular"],
    "subsets": ["math", "symbols", "latin"],
    "category": "display",
    "tags": ["dingbat", "knit"],
    "lineNumber": 1776,
    "variable": false,
    "lastModified": "2025-01-28"
  },
  {
    "family": "Yatra One",
    "variants": ["regular"],
    "subsets": ["devanagari", "latin", "latin-ext"],
    "category": "display",
    "tags": ["brush"],
    "lineNumber": 1777,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yellowtail",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1930s", "brush"],
    "lineNumber": 1778,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Yeon Sung",
    "variants": ["regular"],
    "subsets": ["korean", "latin"],
    "category": "display",
    "tags": ["korean"],
    "lineNumber": 1779,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Yeseva One",
    "variants": ["regular"],
    "subsets": ["cyrillic", "cyrillic-ext", "vietnamese", "latin", "latin-ext"],
    "category": "display",
    "tags": ["didone"],
    "lineNumber": 1780,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yesteryear",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["cursive", "1940s"],
    "lineNumber": 1781,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Yomogi",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "vietnamese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["thin"],
    "lineNumber": 1782,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Young Serif",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["heavy"],
    "lineNumber": 1783,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yrsa",
    "variants": [
      "300",
      "regular",
      "500",
      "600",
      "700",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic"
    ],
    "subsets": ["vietnamese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1784,
    "variable": true,
    "lastModified": "2025-03-11"
  },
  {
    "family": "Ysabeau",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["elegant"],
    "lineNumber": 1785,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ysabeau Infant",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["light", "low contrast"],
    "lineNumber": 1786,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ysabeau Office",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900",
      "100italic",
      "200italic",
      "300italic",
      "italic",
      "500italic",
      "600italic",
      "700italic",
      "800italic",
      "900italic"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["light", "low contrast"],
    "lineNumber": 1787,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Ysabeau SC",
    "variants": [
      "100",
      "200",
      "300",
      "regular",
      "500",
      "600",
      "700",
      "800",
      "900"
    ],
    "subsets": [
      "cyrillic",
      "cyrillic-ext",
      "greek",
      "math",
      "symbols",
      "vietnamese",
      "latin",
      "latin-ext"
    ],
    "category": "sans-serif",
    "tags": ["small caps"],
    "lineNumber": 1788,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yuji Boku",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["handwritten", "playful"],
    "lineNumber": 1789,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yuji Hentaigana Akari",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["japanese", "calligraphic", "handwritten", "caps"],
    "lineNumber": 1790,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Yuji Hentaigana Akebono",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["japanese", "calligraphic", "caps"],
    "lineNumber": 1791,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Yuji Mai",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["swirl"],
    "lineNumber": 1792,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yuji Syuku",
    "variants": ["regular"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["typewriter"],
    "lineNumber": 1793,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Yusei Magic",
    "variants": ["regular"],
    "subsets": ["japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["marker"],
    "lineNumber": 1794,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "ZCOOL KuaiLe",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "sans-serif",
    "tags": ["uneven"],
    "lineNumber": 1795,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "ZCOOL QingKe HuangYou",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1796,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "ZCOOL XiaoWei",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1797,
    "variable": false,
    "lastModified": "2024-08-12"
  },
  {
    "family": "Zain",
    "variants": [
      "200",
      "300",
      "300italic",
      "regular",
      "italic",
      "700",
      "800",
      "900"
    ],
    "subsets": ["arabic", "latin"],
    "category": "sans-serif",
    "tags": [],
    "lineNumber": 1798,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Zen Antique",
    "variants": ["regular"],
    "subsets": ["cyrillic", "greek", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 1799,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Antique Soft",
    "variants": ["regular"],
    "subsets": ["cyrillic", "greek", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": ["japanese"],
    "lineNumber": 1800,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Dots",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["futuristic", "techno"],
    "lineNumber": 1801,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Zen Kaku Gothic Antique",
    "variants": ["300", "regular", "500", "700", "900"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 1802,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Kaku Gothic New",
    "variants": ["300", "regular", "500", "700", "900"],
    "subsets": ["cyrillic", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese"],
    "lineNumber": 1803,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Kurenaido",
    "variants": ["regular"],
    "subsets": ["cyrillic", "greek", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["japanese", "handwritten"],
    "lineNumber": 1804,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Loop",
    "variants": ["regular", "italic"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["light"],
    "lineNumber": 1805,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Zen Maru Gothic",
    "variants": ["300", "regular", "500", "700", "900"],
    "subsets": ["cyrillic", "greek", "japanese", "latin", "latin-ext"],
    "category": "sans-serif",
    "tags": ["round", "cute"],
    "lineNumber": 1806,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Old Mincho",
    "variants": ["regular", "500", "600", "700", "900"],
    "subsets": ["cyrillic", "greek", "japanese", "latin", "latin-ext"],
    "category": "serif",
    "tags": [],
    "lineNumber": 1807,
    "variable": false,
    "lastModified": "2024-08-07"
  },
  {
    "family": "Zen Tokyo Zoo",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "display",
    "tags": ["lines", "outline"],
    "lineNumber": 1808,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Zeyada",
    "variants": ["regular"],
    "subsets": ["latin", "latin-ext"],
    "category": "handwriting",
    "tags": ["scrawl"],
    "lineNumber": 1809,
    "variable": false,
    "lastModified": "2024-11-20"
  },
  {
    "family": "Zhi Mang Xing",
    "variants": ["regular"],
    "subsets": ["chinese-simplified", "latin"],
    "category": "handwriting",
    "tags": ["calligraphic"],
    "lineNumber": 1810,
    "variable": false,
    "lastModified": "2025-01-06"
  },
  {
    "family": "Zilla Slab",
    "variants": [
      "300",
      "300italic",
      "regular",
      "italic",
      "500",
      "500italic",
      "600",
      "600italic",
      "700",
      "700italic"
    ],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1811,
    "variable": false,
    "lastModified": "2024-09-04"
  },
  {
    "family": "Zilla Slab Highlight",
    "variants": ["regular", "700"],
    "subsets": ["latin", "latin-ext"],
    "category": "serif",
    "tags": ["slab"],
    "lineNumber": 1812,
    "variable": false,
    "lastModified": "2024-09-04"
  }
]
