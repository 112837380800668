[
  "adlam",
  "arabic",
  "hebrew",
  "imperial-aramaic",
  "inscriptional-pahlavi",
  "inscriptional-parthian",
  "manichaean",
  "nko",
  "old-south-arabian",
  "old-north-arabian",
  "palmyrene",
  "phoenician",
  "psalter-pahlavi",
  "samaritan",
  "sogdian",
  "syriac",
  "thaana"
]
